import { Nav } from "./components/Nav"
import { LiveBets } from "./components/LiveBets"
import { GameHistory } from "./components/GameHistory"
import { useEffect, useState } from "react"
import { useAuthUser } from "react-auth-kit"
import { useNavigate } from "react-router-dom"

export const WsbController = (props) => {
  const auth = useAuthUser();
  const navigate = useNavigate();

  useEffect(()=>{
    // if (!auth()) {navigate("/login")} 
  })

  return (
    <div id="wsb" className="wsbController">
      <Nav balance={props.balance} user={props.user}/>
      <div className="row">
        <LiveBets/>
        <GameHistory/>
      </div>
      <div className="bottom">bottom</div>
    </div>
  )
}
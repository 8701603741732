import { WsbLogo } from "../../assets/WsbLogo";
import { moneyFormatter } from "../../../../utils/moneyFormatter";
import { useNavigate } from "react-router-dom";
import { phoneFormatter } from "../../../../utils/phoneFormatter";
import { useSignOut } from "react-auth-kit";
import { useState } from "react";

export const Nav = (props) => {
  const navigate = useNavigate();
  const signOut = useSignOut();
  const user = props.user;
  const balance = props.balance;

  const [depositModalOpen, setDepositModalOpen] = useState(false);

  return (
    <nav className="row">
      <div className="col center">
        <div className="row" onClick={() => navigate("/")}>
          <WsbLogo w={25} h={25} v={"10 5 80 80"} />
          <div className="wsbName">
            <div className="wall">wall</div>
            <div className="street">street</div>
            <div className="blast">blast</div>
          </div>
        </div>
      </div>
      <div className="col center middle">
        <div className="gradientBg">
          <div className="blackBg">
            <div className="wallet" onClick={() => navigate("/cashier")}>
              {props.balance < 1
                ? "Deposit"
                : moneyFormatter(balance, "$", true) + " +"}
            </div>
          </div>
        </div>
      </div>
      <div className="col center right">
        <div
          className="username"
          onClick={() => {
            navigate("/account");
          }}
        >
          {user.username === user.phoneNumber
            ? phoneFormatter(user.phoneNumber)
            : user.username}
        </div>
      </div>
    </nav>
  );
};

export const JackpotTotal = (props) => {
  return (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" 
    width={props?.w ?? "306"} 
    height={props?.h ?? "103"} 
    viewBox={props?.v ?? "0 0 306 103"}
  >
    <path d="M16.8281 65.8323L16.3281 36.5C17.061 32.1808 22.9864 30.5703 37.844 29.1515L145.926 16.3132C150.504 15.8843 153.164 15.907 157.935 16.3132L278.026 29.1515C284.96 30.6606 288.063 32.1728 287.828 36.5L287.033 65.8323C287.337 70.1089 285.374 70.9385 278.328 72.5L157.935 87.8409C153.446 88.3497 150.799 88.4703 145.926 87.8409L37.844 75.0026C23.1068 73.2594 17.9964 71.3998 16.8281 65.8323Z" stroke="url(#paint0_linear_4284_471)" strokeWidth="11"/>
    <path d="M18.3901 65.1749V37.2444C19.1146 33.1334 24.4772 31.6123 39.1631 30.2618L145.995 18.0422C150.52 17.6341 153.15 17.6557 157.866 18.0422L276.568 30.2618C283.422 31.6982 285.703 33.1258 285.471 37.2444V51.2097V65.1749C285.772 69.2454 283.533 70.6713 276.568 72.1576L157.866 86.1228C153.429 86.6071 150.812 86.7219 145.995 86.1228L39.1631 73.9032C24.5962 72.2441 19.5449 70.4741 18.3901 65.1749Z" fill="url(#paint1_linear_4284_471)"/>
    <path d="M18.3901 65.1749V37.2444C19.1146 33.1334 24.4772 31.6123 39.1631 30.2618L145.995 18.0422C150.52 17.6341 153.15 17.6557 157.866 18.0422L276.568 30.2618C283.422 31.6982 285.703 33.1258 285.471 37.2444V51.2097V65.1749C285.772 69.2454 283.533 70.6713 276.568 72.1576L157.866 86.1228C153.429 86.6071 150.812 86.7219 145.995 86.1228L39.1631 73.9032C24.5962 72.2441 19.5449 70.4741 18.3901 65.1749Z" fill="url(#paint2_linear_4284_471)"/>
    <path d="M18.3901 65.1749V37.2444C19.1146 33.1334 24.4772 31.6123 39.1631 30.2618L145.995 18.0422C150.52 17.6341 153.15 17.6557 157.866 18.0422L276.568 30.2618C283.422 31.6982 285.703 33.1258 285.471 37.2444V51.2097V65.1749C285.772 69.2454 283.533 70.6713 276.568 72.1576L157.866 86.1228C153.429 86.6071 150.812 86.7219 145.995 86.1228L39.1631 73.9032C24.5962 72.2441 19.5449 70.4741 18.3901 65.1749Z" stroke="#8F130C" strokeWidth="0.5"/>
    <path d="M10.8281 67.9214V33.8133C11.593 28.793 17.2546 26.9355 32.7593 25.2863L145.548 10.364C150.326 9.86556 153.102 9.89195 158.08 10.364L283.401 25.2863C290.637 27.0403 293.046 28.7837 292.8 33.8133V67.9214C293.118 72.8922 290.754 74.6335 283.401 76.4485L158.08 93.5025C153.396 94.0939 150.633 94.2341 145.548 93.5025L32.7593 78.5802C17.3802 76.5541 12.0473 74.3926 10.8281 67.9214Z" stroke="#FDCD14" strokeWidth="2"/>
    <path opacity="0.15" d="M248.828 17.3026C249.547 17.3026 250.131 16.7194 250.131 15.9999C250.131 15.2805 249.547 14.6973 248.828 14.6973C248.109 14.6973 247.525 15.2805 247.525 15.9999C247.525 16.7194 248.109 17.3026 248.828 17.3026Z" fill="#BDBDBD"/>
    <path opacity="0.25" d="M249.59 16.7621C250.011 16.3412 250.011 15.6588 249.59 15.2379C249.169 14.817 248.486 14.817 248.065 15.2379C247.644 15.6588 247.644 16.3412 248.065 16.7621C248.486 17.183 249.169 17.183 249.59 16.7621Z" fill="#BDBDBD"/>
    <path d="M248.828 18C248.955 15.7699 249.058 15.8729 246.828 16C249.058 16.1271 248.955 16.2301 248.828 14C248.701 16.2301 248.598 16.1271 250.828 16C248.598 15.8729 248.701 15.7699 248.828 18Z" fill="#BDBDBD"/>
    <path d="M250.222 17.3923C249.221 16.3912 248.435 16.3912 247.434 17.3923C248.435 16.3912 248.435 15.6046 247.434 14.6035C248.435 15.6046 249.221 15.6046 250.222 14.6035C249.221 15.6046 249.221 16.3912 250.222 17.3923Z" fill="#BDBDBD"/>
    <path d="M249.92 16.0001C248.716 15.9379 248.766 15.8875 248.828 17.0921C248.89 15.8875 248.941 15.9379 247.736 16.0001C248.941 16.0623 248.89 16.1127 248.828 14.9082C248.766 16.1128 248.716 16.0623 249.92 16.0001Z" fill="#BDBDBD"/>
    <path opacity="0.15" d="M285.828 35.56C288.346 35.56 290.387 33.5187 290.387 31.0007C290.387 28.4827 288.346 26.4414 285.828 26.4414C283.31 26.4414 281.269 28.4827 281.269 31.0007C281.269 33.5187 283.31 35.56 285.828 35.56Z" fill="white"/>
    <path opacity="0.25" d="M288.494 33.6674C289.967 32.1943 289.967 29.8057 288.494 28.3326C287.02 26.8594 284.632 26.8594 283.159 28.3326C281.685 29.8057 281.685 32.1943 283.159 33.6674C284.632 35.1406 287.02 35.1406 288.494 33.6674Z" fill="white"/>
    <path d="M285.828 38C286.273 30.1948 286.633 30.5553 278.828 31C286.633 31.4447 286.273 31.8054 285.828 24C285.383 31.8054 285.023 31.4447 292.828 31C285.023 30.5553 285.383 30.1946 285.828 38Z" fill="white"/>
    <path d="M290.708 35.874C287.204 32.3702 284.451 32.3702 280.947 35.874C284.451 32.3702 284.451 29.6172 280.947 26.1133C284.451 29.6171 287.204 29.6171 290.708 26.1133C287.204 29.6171 287.204 32.37 290.708 35.874Z" fill="white"/>
    <path d="M289.65 31.0014C285.434 30.7837 285.611 30.6072 285.829 34.8232C286.046 30.6073 286.223 30.7837 282.007 31.0014C286.223 31.2191 286.046 31.3955 285.829 27.1797C285.611 31.3956 285.434 31.2191 289.65 31.0014Z" fill="white"/>
    <path opacity="0.15" d="M126.828 20.56C129.346 20.56 131.387 18.5187 131.387 16.0007C131.387 13.4827 129.346 11.4414 126.828 11.4414C124.31 11.4414 122.269 13.4827 122.269 16.0007C122.269 18.5187 124.31 20.56 126.828 20.56Z" fill="white"/>
    <path opacity="0.25" d="M129.494 18.6674C130.967 17.1943 130.967 14.8057 129.494 13.3326C128.02 11.8594 125.632 11.8594 124.159 13.3326C122.685 14.8057 122.685 17.1943 124.159 18.6674C125.632 20.1406 128.02 20.1406 129.494 18.6674Z" fill="white"/>
    <path d="M126.828 23C127.273 15.1948 127.633 15.5553 119.828 16C127.633 16.4447 127.273 16.8054 126.828 9C126.383 16.8054 126.023 16.4447 133.828 16C126.023 15.5553 126.383 15.1946 126.828 23Z" fill="white"/>
    <path d="M131.708 20.874C128.204 17.3702 125.451 17.3702 121.947 20.874C125.451 17.3702 125.451 14.6172 121.947 11.1133C125.451 14.6171 128.204 14.6171 131.708 11.1133C128.204 14.6171 128.204 17.37 131.708 20.874Z" fill="white"/>
    <path d="M130.65 16.0014C126.434 15.7837 126.611 15.6072 126.829 19.8232C127.046 15.6073 127.223 15.7837 123.007 16.0014C127.223 16.2191 127.046 16.3955 126.829 12.1797C126.611 16.3956 126.434 16.2191 130.65 16.0014Z" fill="white"/>
    <g opacity="0.7">
    <path opacity="0.15" d="M176.828 91.56C179.346 91.56 181.387 89.5187 181.387 87.0007C181.387 84.4827 179.346 82.4414 176.828 82.4414C174.31 82.4414 172.269 84.4827 172.269 87.0007C172.269 89.5187 174.31 91.56 176.828 91.56Z" fill="white"/>
    <path opacity="0.25" d="M179.494 89.6674C180.967 88.1943 180.967 85.8057 179.494 84.3326C178.02 82.8594 175.632 82.8594 174.159 84.3326C172.685 85.8057 172.685 88.1943 174.159 89.6674C175.632 91.1406 178.02 91.1406 179.494 89.6674Z" fill="white"/>
    <path d="M176.828 94C177.273 86.1948 177.633 86.5553 169.828 87C177.633 87.4447 177.273 87.8054 176.828 80C176.383 87.8054 176.023 87.4447 183.828 87C176.023 86.5553 176.383 86.1946 176.828 94Z" fill="white"/>
    <path d="M181.708 91.874C178.204 88.3702 175.451 88.3702 171.947 91.874C175.451 88.3702 175.451 85.6172 171.947 82.1133C175.451 85.6171 178.204 85.6171 181.708 82.1133C178.204 85.6171 178.204 88.37 181.708 91.874Z" fill="white"/>
    <path d="M180.65 87.0014C176.434 86.7837 176.611 86.6072 176.829 90.8232C177.046 86.6073 177.223 86.7837 173.007 87.0014C177.223 87.2191 177.046 87.3955 176.829 83.1797C176.611 87.3956 176.434 87.2191 180.65 87.0014Z" fill="white"/>
    </g>
    <path opacity="0.15" d="M3.73318 24.938C4.2507 24.4382 4.26509 23.6135 3.76533 23.096C3.26557 22.5785 2.4409 22.5641 1.92338 23.0638C1.40586 23.5636 1.39147 24.3883 1.89123 24.9058C2.39099 25.4233 3.21566 25.4377 3.73318 24.938Z" fill="#BDBDBD"/>
    <path opacity="0.25" d="M3.90506 24.0205C3.91545 23.4254 3.44139 22.9345 2.84622 22.9241C2.25106 22.9137 1.76016 23.3877 1.74977 23.9829C1.73938 24.5781 2.21344 25.069 2.8086 25.0794C3.40377 25.0897 3.89467 24.6157 3.90506 24.0205Z" fill="#BDBDBD"/>
    <path d="M4.21731 25.4392C2.75958 23.7468 2.90525 23.7493 1.38932 25.3899C3.08176 23.9321 3.07924 24.0778 1.43868 22.5619C2.89644 24.2543 2.75074 24.2518 4.26668 22.6112C2.57421 24.069 2.57675 23.9233 4.21731 25.4392Z" fill="#BDBDBD"/>
    <path d="M4.79878 24.0337C3.38325 24.009 2.81744 24.5554 2.79271 25.9709C2.81742 24.5554 2.27102 23.9896 0.855469 23.9648C2.271 23.9896 2.83683 23.4431 2.86154 22.0276C2.8368 23.4432 3.3832 24.009 4.79878 24.0337Z" fill="#BDBDBD"/>
    <path d="M3.61411 23.243C2.70445 24.035 2.70567 23.9637 3.58716 24.787C2.79517 23.8774 2.86647 23.8786 2.04318 24.7601C2.95285 23.9681 2.95161 24.0393 2.07013 23.2161C2.86215 24.1258 2.79086 24.1245 3.61411 23.243Z" fill="#BDBDBD"/>
    <path opacity="0.15" d="M45.828 12.3026C46.5475 12.3026 47.1307 11.7194 47.1307 10.9999C47.1307 10.2805 46.5475 9.69727 45.828 9.69727C45.1086 9.69727 44.5254 10.2805 44.5254 10.9999C44.5254 11.7194 45.1086 12.3026 45.828 12.3026Z" fill="#BDBDBD"/>
    <path opacity="0.25" d="M46.5896 11.7621C47.0105 11.3412 47.0105 10.6588 46.5896 10.2379C46.1687 9.81696 45.4863 9.81696 45.0653 10.2379C44.6444 10.6588 44.6444 11.3412 45.0653 11.7621C45.4863 12.183 46.1687 12.183 46.5896 11.7621Z" fill="#BDBDBD"/>
    <path d="M45.8281 13C45.9552 10.7699 46.0582 10.8729 43.8281 11C46.0582 11.1271 45.9552 11.2301 45.8281 9C45.7011 11.2301 45.598 11.1271 47.8281 11C45.598 10.8729 45.7011 10.7699 45.8281 13Z" fill="#BDBDBD"/>
    <path d="M47.2224 12.3923C46.2213 11.3912 45.4347 11.3912 44.4336 12.3923C45.4347 11.3912 45.4347 10.6046 44.4336 9.60352C45.4347 10.6046 46.2213 10.6046 47.2224 9.60352C46.2212 10.6046 46.2212 11.3912 47.2224 12.3923Z" fill="#BDBDBD"/>
    <path d="M46.9202 11.0001C45.7157 10.9379 45.7661 10.8875 45.8283 12.0921C45.8905 10.8875 45.9409 10.9379 44.7363 11.0001C45.9409 11.0623 45.8905 11.1127 45.8283 9.9082C45.7661 11.1128 45.7157 11.0623 46.9202 11.0001Z" fill="#BDBDBD"/>
    <path opacity="0.15" d="M207.828 11.3026C208.547 11.3026 209.131 10.7194 209.131 9.99992C209.131 9.28048 208.547 8.69727 207.828 8.69727C207.109 8.69727 206.525 9.28048 206.525 9.99992C206.525 10.7194 207.109 11.3026 207.828 11.3026Z" fill="#BDBDBD"/>
    <path opacity="0.25" d="M208.59 10.7621C209.011 10.3412 209.011 9.65878 208.59 9.23787C208.169 8.81696 207.486 8.81696 207.065 9.23787C206.644 9.65878 206.644 10.3412 207.065 10.7621C207.486 11.183 208.169 11.183 208.59 10.7621Z" fill="#BDBDBD"/>
    <path d="M207.828 12C207.955 9.76993 208.058 9.87293 205.828 10C208.058 10.1271 207.955 10.2301 207.828 8C207.701 10.2301 207.598 10.1271 209.828 10C207.598 9.87293 207.701 9.7699 207.828 12Z" fill="#BDBDBD"/>
    <path d="M209.222 11.3923C208.221 10.3912 207.435 10.3912 206.434 11.3923C207.435 10.3912 207.435 9.60463 206.434 8.60352C207.435 9.6046 208.221 9.6046 209.222 8.60352C208.221 9.6046 208.221 10.3912 209.222 11.3923Z" fill="#BDBDBD"/>
    <path d="M208.92 10.0001C207.716 9.93793 207.766 9.88749 207.828 11.0921C207.89 9.88753 207.941 9.93793 206.736 10.0001C207.941 10.0623 207.89 10.1127 207.828 8.9082C207.766 10.1128 207.716 10.0623 208.92 10.0001Z" fill="#BDBDBD"/>
    <path opacity="0.15" d="M97.8788 9.80137C98.5504 9.54355 98.8859 8.79006 98.6281 8.11841C98.3703 7.44676 97.6168 7.11128 96.9451 7.36911C96.2735 7.62693 95.938 8.38042 96.1958 9.05207C96.4537 9.72372 97.2071 10.0592 97.8788 9.80137Z" fill="#BDBDBD"/>
    <path opacity="0.25" d="M98.3959 9.023C98.638 8.47921 98.3935 7.8421 97.8497 7.59999C97.3059 7.35788 96.6688 7.60244 96.4267 8.14623C96.1846 8.69003 96.4291 9.32713 96.9729 9.56924C97.5167 9.81136 98.1538 9.5668 98.3959 9.023Z" fill="#BDBDBD"/>
    <path d="M98.1288 10.4512C97.4482 8.3237 97.5813 8.38293 95.5449 9.30075C97.6723 8.6202 97.6131 8.7533 96.6953 6.71686C97.3759 8.84437 97.2427 8.78511 99.2792 7.86728C97.1517 8.54785 97.2109 8.41473 98.1288 10.4512Z" fill="#BDBDBD"/>
    <path d="M99.2128 9.38538C97.9195 8.80955 97.1851 9.09143 96.6093 10.3848C97.1851 9.09144 96.9032 8.35712 95.6099 7.78125C96.9032 8.35709 97.6376 8.07519 98.2134 6.78185C97.6375 8.07521 97.9194 8.80953 99.2128 9.38538Z" fill="#BDBDBD"/>
    <path d="M98.4321 8.19381C97.2853 8.56741 97.3142 8.50226 97.804 9.60452C97.4304 8.45771 97.4955 8.48669 96.3933 8.97643C97.5401 8.60284 97.5111 8.66795 97.0214 7.56572C97.395 8.71256 97.3298 8.68354 98.4321 8.19381Z" fill="#BDBDBD"/>
    <path opacity="0.15" d="M166.471 3.64668C167.175 3.4971 167.624 2.80537 167.474 2.10166C167.325 1.39794 166.633 0.948724 165.929 1.0983C165.226 1.24788 164.777 1.93961 164.926 2.64333C165.076 3.34704 165.767 3.79626 166.471 3.64668Z" fill="#BDBDBD"/>
    <path opacity="0.25" d="M167.103 2.95956C167.428 2.46033 167.286 1.79282 166.786 1.46862C166.287 1.14442 165.62 1.2863 165.296 1.78553C164.971 2.28475 165.113 2.95227 165.612 3.27647C166.112 3.60067 166.779 3.45878 167.103 2.95956Z" fill="#BDBDBD"/>
    <path d="M166.616 4.3288C166.277 2.12104 166.399 2.20037 164.244 2.78833C166.452 2.44896 166.372 2.57116 165.784 0.416208C166.124 2.624 166.002 2.54463 168.157 1.95668C165.949 2.29606 166.028 2.17385 166.616 4.3288Z" fill="#BDBDBD"/>
    <path d="M167.854 3.44488C166.666 2.67381 165.897 2.83735 165.126 4.0247C165.897 2.83736 165.733 2.06798 164.546 1.29688C165.733 2.06794 166.503 1.9044 167.274 0.717058C166.503 1.90441 166.666 2.67379 167.854 3.44488Z" fill="#BDBDBD"/>
    <path d="M167.268 2.14527C166.077 2.33486 166.116 2.27505 166.427 3.44036C166.238 2.24922 166.298 2.28803 165.132 2.59931C166.323 2.40972 166.285 2.4695 165.973 1.30423C166.163 2.4954 166.103 2.45654 167.268 2.14527Z" fill="#BDBDBD"/>
    <path opacity="0.15" d="M80.9722 91.7537C81.3705 91.1404 81.1962 90.3203 80.5828 89.9219C79.9694 89.5236 79.1493 89.6979 78.751 90.3113C78.3527 90.9247 78.527 91.7448 79.1404 92.1431C79.7537 92.5414 80.5738 92.3671 80.9722 91.7537Z" fill="#BDBDBD"/>
    <path opacity="0.25" d="M80.9327 90.8047C80.8069 90.2128 80.2251 89.835 79.6332 89.9608C79.0413 90.0866 78.6635 90.6684 78.7893 91.2603C78.9151 91.8522 79.4969 92.23 80.0888 92.1042C80.6807 91.9784 81.0585 91.3966 80.9327 90.8047Z" fill="#BDBDBD"/>
    <path d="M81.5663 92.1404C79.7354 90.7974 79.8803 90.7665 78.7539 92.7382C80.0969 90.9073 80.1278 91.0521 78.1561 89.9258C79.9871 91.2688 79.8422 91.2996 80.9685 89.328C79.6255 91.1589 79.5947 91.014 81.5663 92.1404Z" fill="#BDBDBD"/>
    <path d="M81.822 90.6157C80.4142 90.9149 79.9788 91.5855 80.278 92.9932C79.9787 91.5855 79.3081 91.15 77.9004 91.4492C79.3081 91.15 79.7436 90.4794 79.4444 89.0717C79.7436 90.4794 80.4142 90.9149 81.822 90.6157Z" fill="#BDBDBD"/>
    <path d="M80.4657 90.1021C79.7458 91.0946 79.7307 91.0237 80.7921 91.6375C79.7996 90.9176 79.8705 90.9025 79.2566 91.9639C79.9765 90.9714 79.9916 91.0423 78.9302 90.4285C79.9227 91.1484 79.8518 91.1634 80.4657 90.1021Z" fill="#BDBDBD"/>
    <path opacity="0.15" d="M151.977 101.353C152.705 101.289 153.244 100.647 153.181 99.9184C153.117 99.1899 152.474 98.6509 151.746 98.7147C151.017 98.7784 150.478 99.4207 150.542 100.149C150.606 100.878 151.248 101.417 151.977 101.353Z" fill="#BDBDBD"/>
    <path opacity="0.25" d="M152.7 100.737C153.089 100.274 153.029 99.5826 152.565 99.1936C152.102 98.8046 151.411 98.8651 151.022 99.3287C150.633 99.7922 150.693 100.483 151.157 100.872C151.62 101.261 152.311 101.201 152.7 100.737Z" fill="#BDBDBD"/>
    <path d="M152.038 102.059C151.97 99.7895 152.083 99.8847 149.836 100.211C152.105 100.142 152.01 100.255 151.684 98.0084C151.753 100.278 151.639 100.183 153.887 99.8565C151.617 99.9254 151.712 99.812 152.038 102.059Z" fill="#BDBDBD"/>
    <path d="M153.397 101.321C152.294 100.396 151.498 100.466 150.573 101.568C151.498 100.466 151.428 99.6693 150.326 98.7441C151.428 99.6692 152.225 99.5995 153.15 98.4971C152.225 99.5995 152.294 100.396 153.397 101.321Z" fill="#BDBDBD"/>
    <path d="M152.967 99.9377C151.742 99.9814 151.788 99.9259 151.958 101.14C151.914 99.9149 151.97 99.9615 150.755 100.131C151.981 100.087 151.934 100.143 151.764 98.9286C151.808 100.154 151.753 100.107 152.967 99.9377Z" fill="#BDBDBD"/>
    <path opacity="0.15" d="M245.828 88.3358C246.547 88.3358 247.131 87.7526 247.131 87.0331C247.131 86.3137 246.547 85.7305 245.828 85.7305C245.109 85.7305 244.525 86.3137 244.525 87.0331C244.525 87.7526 245.109 88.3358 245.828 88.3358Z" fill="#BDBDBD"/>
    <path opacity="0.25" d="M246.59 87.7953C247.011 87.3744 247.011 86.692 246.59 86.2711C246.169 85.8502 245.487 85.8502 245.066 86.2711C244.645 86.692 244.645 87.3744 245.066 87.7953C245.487 88.2162 246.169 88.2162 246.59 87.7953Z" fill="#BDBDBD"/>
    <path d="M245.828 89.0332C245.955 86.8031 246.058 86.9061 243.828 87.0332C246.058 87.1603 245.955 87.2633 245.828 85.0332C245.701 87.2633 245.598 87.1603 247.828 87.0332C245.598 86.9061 245.701 86.8031 245.828 89.0332Z" fill="#BDBDBD"/>
    <path d="M247.222 88.4274C246.221 87.4264 245.435 87.4264 244.434 88.4274C245.435 87.4264 245.435 86.6398 244.434 85.6387C245.435 86.6398 246.221 86.6398 247.222 85.6387C246.221 86.6398 246.221 87.4263 247.222 88.4274Z" fill="#BDBDBD"/>
    <path d="M246.92 87.0333C245.716 86.9711 245.766 86.9207 245.828 88.1253C245.89 86.9207 245.941 86.9711 244.736 87.0333C245.941 87.0955 245.89 87.1459 245.828 85.9414C245.766 87.146 245.716 87.0955 246.92 87.0333Z" fill="#BDBDBD"/>
    <path opacity="0.15" d="M202.328 101.477C202.868 101.477 203.305 101.04 203.305 100.5C203.305 99.9609 202.868 99.5234 202.328 99.5234C201.788 99.5234 201.351 99.9609 201.351 100.5C201.351 101.04 201.788 101.477 202.328 101.477Z" fill="#BDBDBD"/>
    <path opacity="0.25" d="M202.899 101.072C203.215 100.756 203.215 100.244 202.899 99.9284C202.584 99.6127 202.072 99.6127 201.756 99.9284C201.44 100.244 201.44 100.756 201.756 101.072C202.072 101.387 202.584 101.387 202.899 101.072Z" fill="#BDBDBD"/>
    <path d="M202.328 102C202.423 100.327 202.501 100.405 200.828 100.5C202.501 100.595 202.423 100.673 202.328 99C202.233 100.673 202.156 100.595 203.828 100.5C202.156 100.405 202.233 100.327 202.328 102Z" fill="#BDBDBD"/>
    <path d="M203.374 101.545C202.623 100.794 202.033 100.794 201.282 101.545C202.033 100.794 202.033 100.204 201.282 99.4531C202.033 100.204 202.623 100.204 203.374 99.4531C202.623 100.204 202.623 100.794 203.374 101.545Z" fill="#BDBDBD"/>
    <path d="M203.147 100.501C202.244 100.454 202.282 100.416 202.328 101.32C202.375 100.416 202.413 100.454 201.509 100.501C202.413 100.547 202.375 100.585 202.328 99.6816C202.282 100.585 202.244 100.547 203.147 100.501Z" fill="#BDBDBD"/>
    <path opacity="0.15" d="M279.14 86.3751C279.329 85.6687 278.91 84.9426 278.204 84.7533C277.497 84.564 276.771 84.9832 276.582 85.6897C276.393 86.3961 276.812 87.1222 277.518 87.3115C278.225 87.5008 278.951 87.0815 279.14 86.3751Z" fill="#BDBDBD"/>
    <path opacity="0.25" d="M278.81 85.4848C278.507 84.9607 277.837 84.7812 277.313 85.0838C276.789 85.3863 276.61 86.0564 276.912 86.5804C277.215 87.1045 277.885 87.284 278.409 86.9815C278.933 86.6789 279.112 86.0088 278.81 85.4848Z" fill="#BDBDBD"/>
    <path d="M279.825 86.5593C277.669 85.8478 277.797 85.7737 277.335 87.9969C278.046 85.8406 278.12 85.9689 275.897 85.5069C278.054 86.2184 277.925 86.2925 278.387 84.0693C277.676 86.2256 277.602 86.0973 279.825 86.5593Z" fill="#BDBDBD"/>
    <path d="M279.597 85.0306C278.351 85.7502 278.144 86.5225 278.863 87.7689C278.144 86.5225 277.371 86.3156 276.125 87.0352C277.371 86.3156 277.578 85.5432 276.859 84.2968C277.578 85.5432 278.351 85.7502 279.597 85.0306Z" fill="#BDBDBD"/>
    <path d="M278.149 84.9613C277.771 86.1277 277.734 86.0649 278.934 86.3208C277.767 85.9428 277.83 85.9065 277.574 87.1056C277.952 85.9393 277.988 86.002 276.789 85.7462C277.956 86.1242 277.893 86.1604 278.149 84.9613Z" fill="#BDBDBD"/>
    <path opacity="0.15" d="M303.593 59.7859C304.309 59.6339 304.765 58.9307 304.613 58.2153C304.461 57.5 303.758 57.0433 303.043 57.1954C302.327 57.3474 301.871 58.0506 302.023 58.766C302.175 59.4813 302.878 59.938 303.593 59.7859Z" fill="#BDBDBD"/>
    <path opacity="0.25" d="M304.237 59.0871C304.566 58.5796 304.422 57.901 303.915 57.5715C303.407 57.2419 302.729 57.3861 302.399 57.8936C302.069 58.4011 302.214 59.0797 302.721 59.4092C303.229 59.7388 303.907 59.5946 304.237 59.0871Z" fill="#BDBDBD"/>
    <path d="M303.741 60.4785C303.396 58.2342 303.52 58.3148 301.329 58.9125C303.574 58.5675 303.493 58.6917 302.895 56.5011C303.24 58.7455 303.116 58.6648 305.307 58.0671C303.062 58.4121 303.143 58.2879 303.741 60.4785Z" fill="#BDBDBD"/>
    <path d="M304.999 59.582C303.792 58.7982 303.01 58.9644 302.226 60.1714C303.01 58.9644 302.844 58.1823 301.637 57.3984C302.844 58.1823 303.626 58.016 304.41 56.809C303.626 58.016 303.792 58.7981 304.999 59.582Z" fill="#BDBDBD"/>
    <path d="M304.404 58.2589C303.193 58.4517 303.232 58.3909 303.549 59.5755C303.356 58.3646 303.417 58.4041 302.232 58.7205C303.443 58.5278 303.404 58.5885 303.087 57.404C303.28 58.6149 303.219 58.5754 304.404 58.2589Z" fill="#BDBDBD"/>
    <path opacity="0.15" d="M299.078 27.574C299.677 27.1545 299.823 26.3288 299.403 25.7297C298.984 25.1306 298.158 24.985 297.559 25.4045C296.96 25.824 296.814 26.6497 297.234 27.2488C297.653 27.8479 298.479 27.9935 299.078 27.574Z" fill="#BDBDBD"/>
    <path opacity="0.25" d="M299.397 26.6804C299.502 26.0845 299.104 25.5162 298.508 25.4112C297.912 25.3061 297.344 25.704 297.239 26.2999C297.134 26.8958 297.532 27.4641 298.128 27.5692C298.724 27.6742 299.292 27.2763 299.397 26.6804Z" fill="#BDBDBD"/>
    <path d="M299.485 28.1549C298.29 26.2238 298.436 26.2495 296.653 27.6557C298.584 26.4612 298.558 26.607 297.152 24.8241C298.347 26.7552 298.201 26.7295 299.984 25.3234C298.053 26.5179 298.078 26.372 299.485 28.1549Z" fill="#BDBDBD"/>
    <path d="M300.293 26.8368C298.875 26.5869 298.22 27.0455 297.97 28.4629C298.22 27.0455 297.762 26.3906 296.344 26.1406C297.762 26.3905 298.417 25.9319 298.666 24.5146C298.417 25.9319 298.875 26.5869 300.293 26.8368Z" fill="#BDBDBD"/>
    <path d="M299.228 25.8527C298.188 26.5032 298.201 26.4319 298.955 27.3986C298.305 26.3594 298.376 26.3719 297.409 27.1261C298.448 26.4755 298.436 26.5469 297.682 25.5801C298.332 26.6194 298.261 26.6068 299.228 25.8527Z" fill="#BDBDBD"/>
    <path opacity="0.15" d="M44.3281 83.4774C44.8676 83.4774 45.3051 83.04 45.3051 82.5004C45.3051 81.9609 44.8676 81.5234 44.3281 81.5234C43.7885 81.5234 43.3511 81.9609 43.3511 82.5004C43.3511 83.04 43.7885 83.4774 44.3281 83.4774Z" fill="#BDBDBD"/>
    <path opacity="0.25" d="M44.8994 83.0716C45.215 82.7559 45.215 82.2441 44.8994 81.9284C44.5837 81.6127 44.0718 81.6127 43.7562 81.9284C43.4405 82.2441 43.4405 82.7559 43.7562 83.0716C44.0718 83.3873 44.5837 83.3873 44.8994 83.0716Z" fill="#BDBDBD"/>
    <path d="M44.3281 84C44.4234 82.3274 44.5007 82.4047 42.8281 82.5C44.5007 82.5953 44.4234 82.6726 44.3281 81C44.2328 82.6726 44.1555 82.5953 45.8281 82.5C44.1555 82.4047 44.2328 82.3274 44.3281 84Z" fill="#BDBDBD"/>
    <path d="M45.3738 83.5447C44.623 82.7939 44.0331 82.7939 43.2822 83.5447C44.033 82.7939 44.033 82.204 43.2822 81.4531C44.033 82.2039 44.623 82.2039 45.3738 81.4531C44.623 82.2039 44.623 82.7939 45.3738 83.5447Z" fill="#BDBDBD"/>
    <path d="M45.1472 82.5006C44.2438 82.4539 44.2816 82.4161 44.3282 83.3195C44.3749 82.4161 44.4127 82.4539 43.5093 82.5006C44.4127 82.5472 44.3749 82.585 44.3282 81.6816C44.2816 82.5851 44.2438 82.5472 45.1472 82.5006Z" fill="#BDBDBD"/>
    <path opacity="0.15" d="M12.1294 83.0679C12.167 82.3495 11.6151 81.7365 10.8967 81.6989C10.1782 81.6612 9.5653 82.2131 9.52765 82.9316C9.49 83.65 10.0419 84.2629 10.7603 84.3006C11.4788 84.3382 12.0917 83.7864 12.1294 83.0679Z" fill="#BDBDBD"/>
    <path opacity="0.25" d="M11.6288 82.2801C11.2305 81.8377 10.549 81.802 10.1066 82.2003C9.66426 82.5986 9.62855 83.2801 10.0269 83.7225C10.4252 84.1648 11.1067 84.2005 11.549 83.8022C11.9914 83.4039 12.0271 82.7224 11.6288 82.2801Z" fill="#BDBDBD"/>
    <path d="M12.8253 83.1047C10.6049 82.8611 10.7132 82.7636 10.7233 84.9973C10.9669 82.7769 11.0644 82.8851 8.83075 82.8953C11.0511 83.1389 10.9429 83.2364 10.9327 81.0027C10.6891 83.2231 10.5916 83.1148 12.8253 83.1047Z" fill="#BDBDBD"/>
    <path d="M12.2922 81.6813C11.2401 82.6286 11.199 83.4141 12.1463 84.4663C11.199 83.4142 10.4135 83.373 9.36133 84.3203C10.4134 83.373 10.4546 82.5875 9.50728 81.5354C10.4546 82.5875 11.2401 82.6287 12.2922 81.6813Z" fill="#BDBDBD"/>
    <path d="M10.8859 81.9095C10.7607 83.1091 10.713 83.0561 11.9192 83.0571C10.7195 82.9319 10.7725 82.8842 10.7716 84.0904C10.8967 82.8907 10.9444 82.9437 9.7383 82.9428C10.938 83.0679 10.8849 83.1156 10.8859 81.9095Z" fill="#BDBDBD"/>
    <path d="M259.828 77.6C258.723 77.6 257.828 77.018 257.828 76.3C257.828 75.582 258.723 75 259.828 75C260.933 75 261.828 75.582 261.828 76.3C261.828 77.018 260.933 77.6 259.828 77.6Z" fill="url(#paint3_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M271.828 76C270.723 76 269.828 75.5523 269.828 75C269.828 74.4477 270.723 74 271.828 74C272.933 74 273.828 74.4477 273.828 75C273.828 75.5523 272.933 76 271.828 76Z" fill="url(#paint4_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M246.828 79.6C245.723 79.6 244.828 79.018 244.828 78.3C244.828 77.582 245.723 77 246.828 77C247.933 77 248.828 77.582 248.828 78.3C248.828 79.018 247.933 79.6 246.828 79.6Z" fill="url(#paint5_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M233.828 81C232.724 81 231.828 80.5523 231.828 80C231.828 79.4477 232.724 79 233.828 79C234.933 79 235.828 79.4477 235.828 80C235.828 80.5523 234.933 81 233.828 81Z" fill="url(#paint6_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M221.828 83C220.723 83 219.828 82.3284 219.828 81.5C219.828 80.6716 220.723 80 221.828 80C222.933 80 223.828 80.6716 223.828 81.5C223.828 82.3284 222.933 83 221.828 83Z" fill="url(#paint7_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M209.828 84C208.723 84 207.828 83.5523 207.828 83C207.828 82.4477 208.723 82 209.828 82C210.933 82 211.828 82.4477 211.828 83C211.828 83.5523 210.933 84 209.828 84Z" fill="url(#paint8_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M198.828 86C197.724 86 196.828 85.3284 196.828 84.5C196.828 83.6716 197.724 83 198.828 83C199.933 83 200.828 83.6716 200.828 84.5C200.828 85.3284 199.933 86 198.828 86Z" fill="url(#paint9_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M188.828 87C187.724 87 186.828 86.3284 186.828 85.5C186.828 84.6716 187.724 84 188.828 84C189.933 84 190.828 84.6716 190.828 85.5C190.828 86.3284 189.933 87 188.828 87Z" fill="url(#paint10_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M177.028 88.6C175.813 88.6 174.828 87.7941 174.828 86.8C174.828 85.8059 175.813 85 177.028 85C178.243 85 179.228 85.8059 179.228 86.8C179.228 87.7941 178.243 88.6 177.028 88.6Z" fill="url(#paint11_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M165.028 90C163.813 90 162.828 89.3284 162.828 88.5C162.828 87.6716 163.813 87 165.028 87C166.243 87 167.228 87.6716 167.228 88.5C167.228 89.3284 166.243 90 165.028 90Z" fill="url(#paint12_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M151.528 91.6C150.037 91.6 148.828 90.7941 148.828 89.8C148.828 88.8059 150.037 88 151.528 88C153.019 88 154.228 88.8059 154.228 89.8C154.228 90.7941 153.019 91.6 151.528 91.6Z" fill="url(#paint13_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M139.028 90.6C137.813 90.6 136.828 89.7941 136.828 88.8C136.828 87.8059 137.813 87 139.028 87C140.243 87 141.228 87.8059 141.228 88.8C141.228 89.7941 140.243 90.6 139.028 90.6Z" fill="url(#paint14_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M128.028 89C126.813 89 125.828 88.3284 125.828 87.5C125.828 86.6716 126.813 86 128.028 86C129.243 86 130.228 86.6716 130.228 87.5C130.228 88.3284 129.243 89 128.028 89Z" fill="url(#paint15_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M115.828 87C114.724 87 113.828 86.5523 113.828 86C113.828 85.4477 114.724 85 115.828 85C116.933 85 117.828 85.4477 117.828 86C117.828 86.5523 116.933 87 115.828 87Z" fill="url(#paint16_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <g opacity="0.65">
    <path opacity="0.15" d="M18.8278 35.56C21.3459 35.56 23.3871 33.5187 23.3871 31.0007C23.3871 28.4827 21.3459 26.4414 18.8278 26.4414C16.3098 26.4414 14.2686 28.4827 14.2686 31.0007C14.2686 33.5187 16.3098 35.56 18.8278 35.56Z" fill="white"/>
    <path opacity="0.25" d="M21.4935 33.6674C22.9667 32.1943 22.9667 29.8057 21.4935 28.3326C20.0203 26.8594 17.6318 26.8594 16.1587 28.3326C14.6855 29.8057 14.6855 32.1943 16.1587 33.6674C17.6318 35.1406 20.0203 35.1406 21.4935 33.6674Z" fill="white"/>
    <path d="M18.8281 38C19.2729 30.1948 19.6335 30.5553 11.8281 31C19.6334 31.4447 19.2729 31.8054 18.8281 24C18.3834 31.8054 18.0228 31.4447 25.8281 31C18.0228 30.5553 18.3834 30.1946 18.8281 38Z" fill="white"/>
    <path d="M23.7079 35.874C20.2042 32.3702 17.4512 32.3702 13.9473 35.874C17.4511 32.3702 17.4511 29.6172 13.9473 26.1133C17.4511 29.6171 20.2042 29.6171 23.7079 26.1133C20.204 29.6171 20.204 32.37 23.7079 35.874Z" fill="white"/>
    <path d="M22.6503 31.0014C18.4345 30.7837 18.6109 30.6072 18.8286 34.8232C19.0463 30.6073 19.2228 30.7837 15.0068 31.0014C19.2227 31.2191 19.0463 31.3955 18.8286 27.1797C18.6109 31.3956 18.4345 31.2191 22.6503 31.0014Z" fill="white"/>
    </g>
    <path d="M285.828 73.6C284.723 73.6 283.828 73.018 283.828 72.3C283.828 71.582 284.723 71 285.828 71C286.933 71 287.828 71.582 287.828 72.3C287.828 73.018 286.933 73.6 285.828 73.6Z" fill="url(#paint17_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M288.828 66.6C287.723 66.6 286.828 66.018 286.828 65.3C286.828 64.582 287.723 64 288.828 64C289.933 64 290.828 64.582 290.828 65.3C290.828 66.018 289.933 66.6 288.828 66.6Z" fill="url(#paint18_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M288.828 59.6C287.723 59.6 286.828 59.018 286.828 58.3C286.828 57.582 287.723 57 288.828 57C289.933 57 290.828 57.582 290.828 58.3C290.828 59.018 289.933 59.6 288.828 59.6Z" fill="url(#paint19_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M288.828 52.6C287.723 52.6 286.828 52.018 286.828 51.3C286.828 50.582 287.723 50 288.828 50C289.933 50 290.828 50.582 290.828 51.3C290.828 52.018 289.933 52.6 288.828 52.6Z" fill="url(#paint20_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M288.828 45.6C287.723 45.6 286.828 45.018 286.828 44.3C286.828 43.582 287.723 43 288.828 43C289.933 43 290.828 43.582 290.828 44.3C290.828 45.018 289.933 45.6 288.828 45.6Z" fill="url(#paint21_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M288.828 39.6C287.723 39.6 286.828 39.018 286.828 38.3C286.828 37.582 287.723 37 288.828 37C289.933 37 290.828 37.582 290.828 38.3C290.828 39.018 289.933 39.6 288.828 39.6Z" fill="url(#paint22_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M285.828 32.6C284.723 32.6 283.828 32.018 283.828 31.3C283.828 30.582 284.723 30 285.828 30C286.933 30 287.828 30.582 287.828 31.3C287.828 32.018 286.933 32.6 285.828 32.6Z" fill="url(#paint23_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M273.828 28.6C272.723 28.6 271.828 28.018 271.828 27.3C271.828 26.582 272.723 26 273.828 26C274.933 26 275.828 26.582 275.828 27.3C275.828 28.018 274.933 28.6 273.828 28.6Z" fill="url(#paint24_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M261.828 27.6C260.723 27.6 259.828 27.018 259.828 26.3C259.828 25.582 260.723 25 261.828 25C262.933 25 263.828 25.582 263.828 26.3C263.828 27.018 262.933 27.6 261.828 27.6Z" fill="url(#paint25_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M248.828 25.6C247.723 25.6 246.828 25.2418 246.828 24.8C246.828 24.3582 247.723 24 248.828 24C249.933 24 250.828 24.3582 250.828 24.8C250.828 25.2418 249.933 25.6 248.828 25.6Z" fill="url(#paint26_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M235.828 24.6C234.723 24.6 233.828 24.018 233.828 23.3C233.828 22.582 234.723 22 235.828 22C236.933 22 237.828 22.582 237.828 23.3C237.828 24.018 236.933 24.6 235.828 24.6Z" fill="url(#paint27_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M223.828 23C222.724 23 221.828 22.5523 221.828 22C221.828 21.4477 222.724 21 223.828 21C224.933 21 225.828 21.4477 225.828 22C225.828 22.5523 224.933 23 223.828 23Z" fill="url(#paint28_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M210.828 21.6C209.723 21.6 208.828 21.018 208.828 20.3C208.828 19.582 209.723 19 210.828 19C211.933 19 212.828 19.582 212.828 20.3C212.828 21.018 211.933 21.6 210.828 21.6Z" fill="url(#paint29_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M198.828 20.6C197.723 20.6 196.828 20.018 196.828 19.3C196.828 18.582 197.723 18 198.828 18C199.933 18 200.828 18.582 200.828 19.3C200.828 20.018 199.933 20.6 198.828 20.6Z" fill="url(#paint30_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M187.828 19.6C186.723 19.6 185.828 19.018 185.828 18.3C185.828 17.582 186.723 17 187.828 17C188.933 17 189.828 17.582 189.828 18.3C189.828 19.018 188.933 19.6 187.828 19.6Z" fill="url(#paint31_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M175.328 17.6C174.5 17.6 173.828 17.018 173.828 16.3C173.828 15.582 174.5 15 175.328 15C176.157 15 176.828 15.582 176.828 16.3C176.828 17.018 176.157 17.6 175.328 17.6Z" fill="url(#paint32_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M162.828 16.6C161.723 16.6 160.828 16.018 160.828 15.3C160.828 14.582 161.723 14 162.828 14C163.933 14 164.828 14.582 164.828 15.3C164.828 16.018 163.933 16.6 162.828 16.6Z" fill="url(#paint33_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M150.828 15.6C149.723 15.6 148.828 15.018 148.828 14.3C148.828 13.582 149.723 13 150.828 13C151.933 13 152.828 13.582 152.828 14.3C152.828 15.018 151.933 15.6 150.828 15.6Z" fill="url(#paint34_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M138.828 16.6C137.723 16.6 136.828 16.018 136.828 15.3C136.828 14.582 137.723 14 138.828 14C139.933 14 140.828 14.582 140.828 15.3C140.828 16.018 139.933 16.6 138.828 16.6Z" fill="url(#paint35_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M126.828 17.6C125.723 17.6 124.828 17.018 124.828 16.3C124.828 15.582 125.723 15 126.828 15C127.933 15 128.828 15.582 128.828 16.3C128.828 17.018 127.933 17.6 126.828 17.6Z" fill="url(#paint36_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M113.828 19.6C112.723 19.6 111.828 19.018 111.828 18.3C111.828 17.582 112.723 17 113.828 17C114.933 17 115.828 17.582 115.828 18.3C115.828 19.018 114.933 19.6 113.828 19.6Z" fill="url(#paint37_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M102.828 20.6C101.723 20.6 100.828 20.018 100.828 19.3C100.828 18.582 101.723 18 102.828 18C103.933 18 104.828 18.582 104.828 19.3C104.828 20.018 103.933 20.6 102.828 20.6Z" fill="url(#paint38_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M89.8281 22.6C88.7235 22.6 87.828 22.018 87.828 21.3C87.828 20.582 88.7235 20 89.8281 20C90.9327 20 91.8281 20.582 91.8281 21.3C91.8281 22.018 90.9327 22.6 89.8281 22.6Z" fill="url(#paint39_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M77.8281 24.6C76.7235 24.6 75.828 24.018 75.828 23.3C75.828 22.582 76.7235 22 77.8281 22C78.9327 22 79.8281 22.582 79.8281 23.3C79.8281 24.018 78.9327 24.6 77.8281 24.6Z" fill="url(#paint40_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M65.8281 25.6C64.7235 25.6 63.828 25.018 63.828 24.3C63.828 23.582 64.7235 23 65.8281 23C66.9327 23 67.8281 23.582 67.8281 24.3C67.8281 25.018 66.9327 25.6 65.8281 25.6Z" fill="url(#paint41_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M50.8281 27.6C49.7235 27.6 48.828 27.018 48.828 26.3C48.828 25.582 49.7235 25 50.8281 25C51.9327 25 52.8281 25.582 52.8281 26.3C52.8281 27.018 51.9327 27.6 50.8281 27.6Z" fill="url(#paint42_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M35.8281 28.6C34.7235 28.6 33.828 28.2418 33.828 27.8C33.828 27.3582 34.7235 27 35.8281 27C36.9327 27 37.8281 27.3582 37.8281 27.8C37.8281 28.2418 36.9327 28.6 35.8281 28.6Z" fill="url(#paint43_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M18.8281 32.6C17.7235 32.6 16.828 32.018 16.828 31.3C16.828 30.582 17.7235 30 18.8281 30C19.9327 30 20.8281 30.582 20.8281 31.3C20.8281 32.018 19.9327 32.6 18.8281 32.6Z" fill="url(#paint44_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M14.8281 41.6C13.7235 41.6 12.828 41.018 12.828 40.3C12.828 39.582 13.7235 39 14.8281 39C15.9327 39 16.8281 39.582 16.8281 40.3C16.8281 41.018 15.9327 41.6 14.8281 41.6Z" fill="url(#paint45_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M14.8281 50.6C13.7235 50.6 12.828 50.018 12.828 49.3C12.828 48.582 13.7235 48 14.8281 48C15.9327 48 16.8281 48.582 16.8281 49.3C16.8281 50.018 15.9327 50.6 14.8281 50.6Z" fill="url(#paint46_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M14.8281 59.6C13.7235 59.6 12.828 59.018 12.828 58.3C12.828 57.582 13.7235 57 14.8281 57C15.9327 57 16.8281 57.582 16.8281 58.3C16.8281 59.018 15.9327 59.6 14.8281 59.6Z" fill="url(#paint47_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M14.8281 68.6C13.7235 68.6 12.828 68.018 12.828 67.3C12.828 66.582 13.7235 66 14.8281 66C15.9327 66 16.8281 66.582 16.8281 67.3C16.8281 68.018 15.9327 68.6 14.8281 68.6Z" fill="url(#paint48_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M21.8281 74.6C20.7235 74.6 19.828 74.018 19.828 73.3C19.828 72.582 20.7235 72 21.8281 72C22.9327 72 23.8281 72.582 23.8281 73.3C23.8281 74.018 22.9327 74.6 21.8281 74.6Z" fill="url(#paint49_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M32.8281 76.6C31.7235 76.6 30.828 76.018 30.828 75.3C30.828 74.582 31.7235 74 32.8281 74C33.9327 74 34.8281 74.582 34.8281 75.3C34.8281 76.018 33.9327 76.6 32.8281 76.6Z" fill="url(#paint50_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M46.8281 78.6C45.7235 78.6 44.828 78.018 44.828 77.3C44.828 76.582 45.7235 76 46.8281 76C47.9327 76 48.8281 76.582 48.8281 77.3C48.8281 78.018 47.9327 78.6 46.8281 78.6Z" fill="url(#paint51_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M61.8281 80.6C60.7235 80.6 59.828 80.018 59.828 79.3C59.828 78.582 60.7235 78 61.8281 78C62.9327 78 63.8281 78.582 63.8281 79.3C63.8281 80.018 62.9327 80.6 61.8281 80.6Z" fill="url(#paint52_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M74.8281 82C73.7235 82 72.828 81.3284 72.828 80.5C72.828 79.6716 73.7235 79 74.8281 79C75.9327 79 76.8281 79.6716 76.8281 80.5C76.8281 81.3284 75.9327 82 74.8281 82Z" fill="url(#paint53_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M89.8281 84C88.7235 84 87.828 83.3284 87.828 82.5C87.828 81.6716 88.7235 81 89.8281 81C90.9327 81 91.8281 81.6716 91.8281 82.5C91.8281 83.3284 90.9327 84 89.8281 84Z" fill="url(#paint54_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <path d="M102.828 86C101.723 86 100.828 85.3284 100.828 84.5C100.828 83.6716 101.723 83 102.828 83C103.933 83 104.828 83.6716 104.828 84.5C104.828 85.3284 103.933 86 102.828 86Z" fill="url(#paint55_radial_4284_471)" stroke="#FFF200" strokeMiterlimit="10"/>
    <defs>
      <linearGradient id="paint0_linear_4284_471" x1="152.204" y1="8.59897" x2="152.204" y2="97.4113" gradientUnits="userSpaceOnUse">
      <stop stopColor="#F77700" stopOpacity="0.85"/>
      <stop offset="0.198897" stopColor="#FDA400"/>
      <stop offset="0.814549" stopColor="#FDA400"/>
      <stop offset="1" stopColor="#F98D03" stopOpacity="0.85"/>
      </linearGradient>
      <linearGradient id="paint1_linear_4284_471" x1="151.944" y1="17.7441" x2="151.944" y2="86.5302" gradientUnits="userSpaceOnUse">
      <stop stopColor="#570302"/>
      <stop offset="1" stopColor="#930C0C"/>
      </linearGradient>
      <linearGradient id="paint2_linear_4284_471" x1="151.944" y1="17.7441" x2="151.944" y2="86.5302" gradientUnits="userSpaceOnUse">
      <stop stopColor="#570302"/>
      <stop offset="0.486516" stopColor="#930C0C"/>
      <stop offset="1" stopColor="#4C0201"/>
      </linearGradient>
      <radialGradient id="paint3_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(259.828 76.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint4_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(271.828 75) rotate(180) scale(2.00005 1)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint5_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(246.828 78.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint6_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(233.828 80) rotate(180) scale(2 1)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint7_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(221.828 81.5) rotate(180) scale(2.00005 1.5)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint8_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(209.828 83) rotate(180) scale(2.00005 1)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint9_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(198.828 84.5) rotate(180) scale(2 1.5)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint10_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(188.828 85.5) rotate(180) scale(2 1.5)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint11_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(177.028 86.8) rotate(180) scale(2.19995 1.8)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint12_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(165.028 88.5) rotate(180) scale(2.19995 1.5)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint13_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(151.528 89.8) rotate(180) scale(2.7 1.8)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint14_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(139.028 88.8) rotate(180) scale(2.19995 1.8)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint15_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(128.028 87.5) rotate(180) scale(2.19995 1.5)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint16_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(115.828 86) rotate(180) scale(2 1)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint17_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(285.828 72.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint18_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(288.828 65.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint19_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(288.828 58.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint20_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(288.828 51.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint21_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(288.828 44.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint22_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(288.828 38.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint23_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(285.828 31.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint24_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(273.828 27.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint25_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(261.828 26.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint26_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(248.828 24.8) rotate(180) scale(2.00005 0.8)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint27_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(235.828 23.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint28_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(223.828 22) rotate(180) scale(2 1)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint29_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(210.828 20.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint30_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(198.828 19.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint31_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(187.828 18.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint32_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(175.328 16.3) rotate(180) scale(1.50005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint33_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(162.828 15.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint34_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(150.828 14.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint35_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(138.828 15.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint36_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(126.828 16.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint37_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(113.828 18.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint38_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(102.828 19.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint39_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(89.8281 21.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint40_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(77.8281 23.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint41_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(65.8281 24.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint42_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(50.8281 26.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint43_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(35.8281 27.8) rotate(180) scale(2.00005 0.8)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint44_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18.8281 31.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint45_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.8281 40.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint46_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.8281 49.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint47_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.8281 58.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint48_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.8281 67.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint49_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(21.8281 73.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint50_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.8281 75.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint51_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(46.8281 77.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint52_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(61.8281 79.3) rotate(180) scale(2.00005 1.3)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint53_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(74.8281 80.5) rotate(180) scale(2.00005 1.5)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint54_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(89.8281 82.5) rotate(180) scale(2.00005 1.5)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
      <radialGradient id="paint55_radial_4284_471" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(102.828 84.5) rotate(180) scale(2.00005 1.5)">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="#FFF200"/>
      </radialGradient>
    </defs>
  </svg>
  )
}
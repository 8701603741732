export const NoFlame15 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="284.21 682.84 315.62 636.23 487.69 588.21 552.99 757.99 383.99 823.67 338.28 835.96 284.21 682.84"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m487.64,585.46l-168.92,47.11c-2.31.64-4.31,2.09-5.64,4.09l-29.4,43.99c-.29.44-.36.98-.19,1.48l54.88,155.24c.29.83,1.18,1.29,2.03,1.04l43.94-12.82s.09-.03.13-.04l169.89-65.06c.86-.33,1.28-1.29.96-2.14l-65.69-171.87c-.31-.8-1.17-1.24-2-1.01Zm-149.57,246.63l-52.25-146.51c-.18-.5-.11-1.05.19-1.49l27.95-41.07c.76-1.12,2.47-.9,2.93.37l63.36,175.39c.32.89-.16,1.87-1.07,2.15l-39.06,12.19c-.85.27-1.76-.19-2.06-1.03Zm-13.58-195.26l160.49-45.19c.83-.23,1.7.2,2,1.01l61.88,162.67c.33.86-.11,1.82-.97,2.14l-158.29,59.74c-.87.33-1.83-.12-2.15-.99l-64.07-177.22c-.33-.91.18-1.9,1.11-2.16Z"/>
          <ellipse cx="439.72" cy="698.38" rx="18.5" ry="19.85" transform="translate(-205.22 183.46) rotate(-19.2)"/>
          <ellipse cx="305.58" cy="713.1" rx="4.35" ry="17.49" transform="translate(-217.52 140.16) rotate(-19.2)"/>
          <ellipse cx="353.8" cy="781.34" rx="5.47" ry="19.59" transform="translate(-237.28 159.82) rotate(-19.2)"/>
        </g>
      </g>
    </svg>
  )
}
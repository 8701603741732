export const i18nEn = {
    back: "Back",
    makeADeposit: "Make A Deposit",
    makeAWithdrawal: "Make A Withdrawal",
    returnHome: "RETURN HOME",
    depositRequest: "Deposit Request",
    withdrawRequest: "Withdraw Request",
    amountToDeposit: "Amount to Deposit",
    amountToWithdraw: "Amount to Withdraw",
    depositType: "Deposit Type",
    withdrawalType: "Withdrawal Type",
    cash: "Cash",
    location: "Location",
    selectAnOption: "Select an Option",
    verifyMsg: "Verify your details then tap 'Continue'.",
    continue: "CONTINUE",
    
    depositRequested: "DEPOSIT REQUESTED",
    withdrawalRequested: "WITHDRAWAL REQUESTED",
    requestPageInstructions1: "Share the code below with your server.",
    requestPageInstructions2: "Give them the cash to deposit.",
    requestPageInstructions3: "Your account will be credited.",
    requestPageInstructions4: "Claim your Prize!",
    cancelRequestMsg: "Tap here to cancel your request.",
    completeRequestMsg: "Após a conclusão da solicitação, você voltará à tela inicial.",
    // Cancel Modal
    confirmToCancel: "Are you sure you want to cancel?",
    confirm: "CONFIRM",
    no: "NO",
};

export const i18nPt = {
    back: "< Voltar",
    makeADeposit: "Depósito",
    makeAWithdrawal: "Retirada",
    returnHome: "VOLTAR PARA INÍCIO",
    depositRequest: "Depósito",
    withdrawRequest: "Retirada",
    amountToDeposit: "Valor a ser depositado",
    amountToWithdraw: "Valor a ser retirado",
    depositType: "Depósito",
    withdrawalType: "Retirada",
    cash: "Dinheiro",
    location: "Localização",
    selectAnOption: "Selecione uma Opção",
    verifyMsg: "Verifique seus detalhes e toque em 'Continuar'.",
    continue: "CONTINUAR",
    
    depositRequested: "DEPÓSITO SOLICITADO",
    withdrawalRequested: "RETIRADA SOLICITADA",
    requestPageInstructions1: "Compartilhe o código abaixo com seu servidor.",
    requestPageInstructions2: "Dê a eles o dinheiro para depositar.",
    requestPageInstructions3: "Sua conta será creditada.",
    requestPageInstructions4: "Solicite seu prêmio!",
    cancelRequestMsg: "Toque aqui para cancelar sua solicitação.",
    completeRequestMsg: "Após a conclusão da solicitação, você voltará à tela inicial.",
    // Cancel Modal
    confirmToCancel: "Tem certeza que quer cancelar?",
    confirm: "CONFIRMAR",
    no: "NÃO",
}
import { PhoneIcon } from "../../assets/PhoneIcon"
import { handlePhoneNumberChange } from "../../../../utils/handlePhoneNumberChange";
import { useEffect, useState } from "react";

export const PhoneEntry = (props) => {
  const { phoneNumber, setPhoneNumber, setIsValid, handleSend } = props

  const [availHeight, setAvailHeight] = useState(0)
  const [innerHeight, setInnerHeight] = useState(0)
  const [outerHeight, setOuterHeight] = useState(0)
  const [clientHeight, setClientHeight] = useState(0)
  useEffect(()=>{
    setAvailHeight(window.screen.availHeight)
    setInnerHeight(window.innerHeight)
    setOuterHeight(window.outerHeight)
    setClientHeight(document.documentElement.clientHeight)
    console.log(availHeight, innerHeight, outerHeight, clientHeight)
  })

  return (
    <div className="phoneEntry">
      <div className="title-wrapper">
        <div className="title">We're going to text you a code</div>
      </div>
      <div className="gradientBg">
        <div className="blackBg">
          <div className="phoneLine">
            <div className="phoneIcon">
              <PhoneIcon w={"14"} h={"14"} v={"10 5 14 14"}/>
            </div>
            <input 
              className="invisibleInput"
              type="tel"
              placeholder="Enter your phone number to play"
              value={phoneNumber}
              onChange={(e)=>handlePhoneNumberChange(e, setIsValid, setPhoneNumber)}
              onKeyUp={(e)=>{
                if (e.key === "Enter") {handleSend()}
              }}
            />
          </div>
        </div>
      </div>
      <div>{`${availHeight} ${innerHeight} ${outerHeight} ${clientHeight}`}</div>
    </div>
  )
}
import { useEffect, useState, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAuthHeader, useAuthUser } from "react-auth-kit"
import { BarDiceNav } from "./components/BarDiceNav"
import { YellowMinus } from "./assets/YellowMinus"
import { YellowPlus } from "./assets/YellowPlus"
import { PenEditButton } from "./assets/PenEditButton"
import { moneyFormatter } from "../../../utils/moneyFormatter"
import { Die } from "../assets/cube/Die"
import { fetcher } from "../../../utils/fetcher"
import { Slider } from "./components/Slider"
import { Button } from "./components/Button";
import { i18nEn, i18nPt } from "./assets/i18n"

export const JogoDoDado = (props) => {
  const navigate = useNavigate()
  const auth = useAuthUser()
  const authHeader = useAuthHeader()
  const location = useLocation()
  const i18n = props.jogo ? i18nPt: i18nEn;
  
  useEffect(()=>{
    if (!auth()) {
      navigate("/login", {replace: true, state: {}}
    )}
  })

  // const [player, setPlayer] = useState({})
  const [bet, setBet] = useState(0)
  const [pendingBet, setPendingBet] = useState(1)
  const [guess, setGuess] = useState("2.00")
  const [cashedOut, setCashedOut] = useState(false)
  const [profit, setProfit] = useState("0.00")
  const [tickets, setTickets] = useState("0")

  useEffect(()=>{
    if (auth()?.id) {
      (async () => {
        await fetcher(
          "GET", authHeader(), 
          props.backend+`/users/${auth().id}`
        ).then(data=>{
          setTickets(data.details["Jogo Do Dado"] ?? 0)
        }).catch(e=>{console.log(e)})
      })();
    }
  }, [props.user])

  const [buttonClass, setButtonClass] = useState("")
  const [buttonText, setButtonText] = useState("")

  const [sliderValue, setSliderValue] = useState(100)
  const [input, setInput] = useState("")
  const [inputFocused, setInputFocused] = useState(false)

  const diceGuessInputRef = useRef();

  // GAME LOOP
  useEffect(()=>{
    
    if (props.phase === "preGame") {
      setButtonClass(bet===0 ? "confirm" : "disabled")
      setButtonText(bet===0 ? i18n.placeBet : i18n.inNextRoll)
    } else {
      setButtonClass("disabled")
      setButtonText(i18n.waitNextRoll)
    }
  }, [props.phase, bet])

  useEffect(()=>{
    if (props.phase === "preGame") {
      if (bet) {setBet(0)}
      setCashedOut(false) 
    }
  }, [props.phase])

  useEffect(()=>{
    setProfit((Math.round(pendingBet*(parseFloat(guess).toFixed(2)-1)*100)/100).toFixed(2))
  }, [pendingBet, parseFloat(guess).toFixed(2)])

  useEffect(() => {
    if (bet===0) {
      for (let player of props.players) {
        if (player.id === props.user.id) {
          console.log("player", player)
          setBet(player.bet)
          setPendingBet(player.bet)
          setGuess(parseFloat(player.guess).toFixed(2))
          setCashedOut(!!player?.multiplier)
        }
      }
    }
  }, [props.startTime])

  useEffect(()=>{
    if (parseFloat(props.multiplier) >= parseFloat(guess) && bet && !cashedOut && props.phase!=="preGame") {
      console.log("cashout initiated")
      setCashedOut(true)
      props.socket.current.emit("cashout", {
        id: props.user.id,
        multiplier: parseFloat(guess),
      })
    }
  }, [props.multiplier])

  // BUTTON FUNCTIONALITY
  const handleClick = (e) => {
    if (props.phase === "preGame" && bet===0) {
      if ("vibrate" in navigator) {
        navigator.vibrate(100);
      }
      if (
        bet === 0 &&
        pendingBet > 0 &&
        props.balance >= pendingBet &&
        pendingBet <= 100
      ) {
        setBet(pendingBet);
        props.socket.current.emit("playerBet", {
          id: props.user.id,
          username: props.user.username,
          locationId: props.selectedLocation?.id,
          gameName: "Jogo Do Dado",
          bet: pendingBet,
          guess: guess,
          profit: profit,
        });
        setTickets(tickets+pendingBet)
      } else if (pendingBet > 100) {
        // setMaxBetToastVisible(true);
      } else if (props.balance < pendingBet) {
        // setLowBalanceToastVisible(true);
      } else if (pendingBet === 0 || pendingBet === "") {
        // props.setNoBetToastVisible(true);
      }
    }
  }

  const adjustBet = (num) => {
    if (bet>0) {return true}
    const adjustedBet = Number(pendingBet)+num
    if (num>0) {
      if (adjustedBet > props.balance) {
        alert(i18n.insufficientFundsWarning);
        // return setLowBalanceToastVisible(true);
      } else if (adjustedBet > 100) {
        // return setMaxBetToastVisible(true);
      } else {
        return setPendingBet(adjustedBet);
      }
    }
    if (num<0) {
      if (adjustedBet <= 0) {
        // return setNoBetToastVisible(true);
      } else {
        return setPendingBet(adjustedBet);
      }
    }
  }

  // const sliderAlgo = (x) => {
  //   const k = Math.log(9900)/99
  //   const a = 1/(Math.E**k)
  //   return a*Math.E**(k*x)
  // }

  // const reverseAlgo = (y) => {
  //   const k = Math.log(9900)/99
  //   const a = 1/(Math.E**k)
  //   return Math.floor(Math.log((y-1)/a)/k)+50
  // }

  // Takes rawGuess: string
  // Returns position: string
  function getSliderPosition(rawGuess) {
    let guess = parseFloat(rawGuess);
    let position = 0;
    // When guessing between 1.01-2.00, Position increments by 0.01, return Position between 1-100
    if(guess >= 1.00 && guess <= 2) {
        position = (guess - 1) / 0.01;
    }
    if(guess >= 2.01 && guess <= 2.24) {
      position = 100;
    }
    // When guessing between 2.01-5.00, Position increments by 0.50, return Position between 101-106 (rounded to closest)
    if(guess >= 2.25 && guess <= 2.49) {
      position = 101;
    }
    if(guess >= 2.5 && guess <= 5) {
        position = Math.ceil((guess - 2) / 0.5) + 100;
    }
    if(guess >= 5.01 && guess <= 5.49) {
      position = 106;
    }
    // When guessing between 5.01-100, Position increments by 1, return Position between 107-201.
    if(guess >= 5.50) {
        position = Math.ceil(guess-5) + 106;
    }
    // Clean up rounding
    position = Math.round((position + Number.EPSILON) * 100) / 100;
    // console.log(`getSliderPosition: guess: ${guess} (typeof ${typeof guess}), position: ${position} (typeof ${typeof position})` );
    return position.toString();
  }

  // Takes rawSliderPosition: string
  // Returns guess: string
  function getGuess(rawSliderPosition) {
    let base = 0;
    let rate = 0;
    let multiplier = 0;
    let position = parseInt(rawSliderPosition);
    // When sliding between 1-2, show each individual 'dice guess'. (Ex. 1.01-2) 
    // 100 slider positions needed
    if(position >= 1 && position <= 100) {
        base = 1;
        rate = 0.01;
        multiplier = position;
    }
    // Once a user slides past Guess 2.00, adjust 'dice guess' at increments of 0.50.
    // Ex. eg. 2.00, 2.50, 3.00, 3.5, 4.0, 4.5, 5.0)
    // 6 slider positions neeeded
    if(position >= 101 && position <= 106) {
        base = 2;
        rate = 0.5;
        multiplier = position - 100;
    }
    // Once 5.00 is reached, increment at a rate of 1.00. Ex: 6.0, 7.0, all the way to 100.
    // 95 slider positions needed
    if(position >= 107) {
        base = 5;
        rate = 1;
        multiplier = position - 106;
    }
    let guess = (parseFloat(base + rate * multiplier)).toFixed(2);
    // console.log(`getGuess: position: ${position} (typeof ${typeof position}), guess: ${guess} (typeof ${typeof guess})` );
    return guess;
}

  // Given Slider value/position, get Guess value
  const handleSlider = (e) => {
    // console.log(`handleSlider ${e.target.value} ${typeof e.target.value}`)
    if (bet>0) {return true}
    setGuess(getGuess(e.target.value))
    setInput(getGuess(e.target.value))
  }

  useEffect(()=>{
    setSliderValue(getSliderPosition(guess))
  }, [parseFloat(guess).toFixed(2)])


  useEffect(()=>{
    let root = document?.documentElement;
    let yellowLength = sliderValue/201*100;
    root?.style?.setProperty('--slider', yellowLength+'%')
  }, [sliderValue])

  const roundOrIncrement = (value, one) => {
    value = parseFloat((Math.round(value*100)/100).toFixed(2))
    let decimal = value<2 ? 100 : value>2 ? 1 : one>0 ? 1 : 100
    let result = value + 1/decimal * one
    return result >= 1.01 && result <= 100 ? result : value
  }

  const adjustGuess = (one) => {
    if (bet>0) {return true}
    let adjustedGuess = roundOrIncrement(parseFloat(guess).toFixed(2), one)
    setGuess(adjustedGuess)
    setInput(adjustedGuess.toFixed(2))
  }

  const roundSlider = (e) => {
    // if (parseFloat(guess).toFixed(2) > 2 && bet===0) {
    //   let rounded = Math.round(1+sliderAlgo(e.target.value)/100)
    //   setGuess(rounded)
    //   setInput(rounded.toFixed(2))
    // }
    if (bet===0) {
      setGuess(getGuess(e.target.value));
      setInput(getGuess(e.target.value))
    }
  }

  const handleInput = (e) => {
    if (bet === 0) {
      let entry = Number(e.target.value)
      if (isNaN(entry)) { setInput("") }
      if ((entry >= 1.00 && entry <= 100)) {
        setInput(e.target.value)
        setGuess(entry === 1 ? 1.01 : entry)
      }
    }
  }

  const finalizeInput = (e) => {
    if (e.target.value != "" && bet === 0) {
      let guess = Number(e.target.value).toFixed(2)
      guess = guess<1.01 ? "1.01" : guess>100 ? "100.00" : guess
      setInput(guess)
      setGuess(guess)
    }
    setInputFocused(false)
  }

  const handleKeys = (e) => {
    if (e.key==="Enter" 
      && e.target.value !== "" 
      && bet === 0
    ) {
      finalizeInput(e)
    }

    if (e.key==="Backspace" 
      && e.target.value.length === 1 
      && bet === 0
    ) {
      setInput("")
    }
  }

  const handleFocus = (e) => {
    if (bet===0) {
      setInput("")
      setInputFocused(true)
    }
  }

  const x = useRef(0)
  const y = useRef(0)

  // CHART OBJECT
  const [chartH, setChartH] = useState(0)
  const [chartW, setChartW] = useState(0)
  const [canvasRatio, setCanvasRatio] = useState(0)
  const [canvasH, setCanvasH] = useState(0)
  const [canvasW, setCanvasW] = useState(0)
  const [graphElement, setGraphElement] = useState();
  const [yElement, setYElement] = useState();
  const [xElement, setXElement] = useState();
  const [graph, setGraph] = useState();
  const [yAxis, setYAxis] = useState();
  const [xAxis, setXAxis] = useState();
  // const [yLabels, setYLabels] = useState([]);
  // const [xLabels, setXLabels] = useState([]);

  // CHART SETUP
  useEffect(()=>{
    setChartH(document.documentElement.querySelector("#chartContainer").clientHeight)
    setChartW(document.documentElement.querySelector("#chartContainer").clientWidth)
  }, [graphElement, yElement, xElement])

  // CALCULATE POSITION
  useEffect(()=>{
    setCanvasRatio((chartH*0.9)/(chartW-chartH*0.1))
    setCanvasH(chartH*0.9)
    setCanvasW(chartW-chartH*0.1)
    setGraphElement(document.getElementById("graph"));
    setYElement(document.getElementById("y-axis"));
    setXElement(document.getElementById("x-axis"));
  }, [chartH, chartW])

  useEffect(()=>{
    setGraph(graphElement?.getContext("2d"));
    setYAxis(yElement?.getContext("2d"));
    setXAxis(xElement?.getContext("2d"));
  }, [graphElement, yElement, xElement])

  useEffect(()=>{
    if (canvasW && canvasH) {
      x.current = canvasW*(0.90-0.91**props.timeElapsed)
      y.current = canvasH*(1-0.85*props.multiplier/100)
    }
  }, [props.multiplier])

  return (
    <div id="barDice" className="barDiceController">
      <BarDiceNav
        balance={props.balance}
        user={props.user}
        selectedLocation={props.selectedLocation}
        bet={bet}
        jogo={props.jogo}
        jackpot={props.jackpot}
        modalOpen={props.modalOpen}
        modalData={props.modalData}
        setModalOpen={props.setModalOpen}
        setModalData={props.setModalData}
      />
      <div className="jogoMarquee">
        {props?.history?.map((crashpoint, i) => {
          return (
            <div key={`history${i}`}>
              <span className={crashpoint >= 2 ? "yellow" : "red"}>
                {crashpoint}
              </span>
            </div>
          )
        })}
      </div>
      <div className="gradientBg">
        <div className="blackBg">
          <div id="chartContainer" className={`barDiceScreen ${props.phase !== "preGame" ? "starBg" : ""}`}>
            <div className="barDiceChart">
              <div className={props.phase+" chartText"}>
                {props.phase==="preGame" ? (<>
                  <div>{i18n.nextRollIn}</div>
                  <div>{props.timeRemaining}s</div>
                </>) : props.phase === "inGame" ? (
                  <div>{props.multiplier}</div>
                ) : props.busted ? (
                  <div className={
                    parseFloat(props.multiplier) > 2 
                    ? "yellow"
                    : "red"
                  }>
                    <div>{i18n.rolled}</div>
                    <div>{props.busted}</div>
                  </div>
                ) : (<></>)}
              </div>
              <canvas id="y-axis" style={{
                height: chartH*0.9, 
                width: chartH*0.1,
              }}/>
              <canvas id="graph"
                height={chartH*0.9}
                width={chartW-chartH*0.1}
                style={{
                  // height: chartH*0.9, 
                  // width: chartW-chartH*0.1, 
                  left: chartH*0.1
                }}
              />
              <canvas id="x-axis" 
                height={chartH*0.1}
                width={chartW-chartH*0.1}
                style={{
                  // height: chartH*0.1, 
                  // width: chartW-chartH*0.1,
                  top: chartH*0.9,
                  left: chartH*0.1
                }}
              />
              <Die 
                timeElapsed={props.timeElapsed}
                multiplier={props.multiplier}
                phase={props.phase}
                x={x.current*0.9}
                y={y.current}
              />
            </div>
            <div className="jackpotController">
              <div className="ticketWrapper">
                <div className="jackpotTickets" onClick={()=>{props.setModalOpen(true)}}>
                  {tickets}
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>  
      <div className={`barDiceButtons ${props.phase==="postGame" && bet && !cashedOut ? "disabled" : ""}`}>
        {bet ?
          (<div className="gradientBg">
            <div className="blackBg">
              <div className={`barDiceScreen-lower ${cashedOut ? "starBg" : ""}`}>
                {cashedOut ? (
                  <>
                    <div className="green">{i18n.youWon}</div>
                    <div className="green">
                      {moneyFormatter(bet*guess, i18n.currency, true)}!
                    </div>
                  </>) : bet ? (
                  <div className="slightlySmallerText">
                    <div>{i18n.bet}: {moneyFormatter(bet, i18n.currency, true)}</div>
                    <div>{i18n.guess}: {guess}</div>
                    <div>{i18n.potentialWin}: <br/>
                      <span className="payout">
                        {moneyFormatter(bet*guess, i18n.currency, true)}
                      </span>
                    </div>
                  </div>) : (<></>)
                }
              </div>
            </div>
          </div>) : (
          <div className="gradientBg">
            <div className="blackBg">
              <div className="adjusters">
                <div className="row">
                  <div className="gradientBg square">
                    <div className="blackBg">
                      <div>{i18n.bet}</div>
                      <div className="row limit">
                        <div className="limit-left" onClick={(e)=>{adjustBet(-1)}}>
                          <YellowMinus w={25} h={25} v={"8 4 19 19"} />
                        </div>
                        <div className="betAmount">
                          {moneyFormatter(pendingBet, i18n.currency, true)}
                        </div>
                        <div className="limit-right" onClick={(e)=>{adjustBet(1)}}>
                          <YellowPlus w={25} h={25} v={"8 4 19 19"} />
                        </div>
                      </div>
                    </div>
                  </div>
            
                  <div className="square quickBets">      
                    <div className="row">
                      <div className="quickBet" onClick={(e)=>{
                        adjustBet(i18n.quickBets[0]-pendingBet)
                      }}>
                        {moneyFormatter(i18n.quickBets[0], i18n.currency)}
                      </div>
                      <div className="quickBet" onClick={(e)=>{
                        adjustBet(i18n.quickBets[1]-pendingBet)
                      }}>
                        {moneyFormatter(i18n.quickBets[1], i18n.currency)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="quickBet" onClick={(e)=>{
                        adjustBet(i18n.quickBets[2]-pendingBet)
                      }}>
                        {moneyFormatter(i18n.quickBets[2], i18n.currency)}
                      </div>
                      <div className="quickBet" onClick={(e)=>{
                        adjustBet(i18n.quickBets[3]-pendingBet)
                      }}>
                        {moneyFormatter(i18n.quickBets[3], i18n.currency)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="fill">
                    <div className="row">
                      <div className="gradientBg square">
                        <div className="blackBg">
                          <div>{i18n.diceGuess}</div>
                          <div className="diceGuess">
                            <input
                              id="guess"
                              className="guessInput"
                              type="decimal"
                              inputMode="decimal"
                              placeholder={guess}
                              onChange={handleInput}
                              value={input}
                              onFocus={handleFocus}
                              onBlur={finalizeInput}
                              onKeyUp={handleKeys}
                              ref={diceGuessInputRef}
                            />

                            <PenEditButton 
                              width="24" 
                              height="24" 
                              color="yellow"
                              onClick={() => console.log("clicked")}
                              targetRef={diceGuessInputRef}
                            />

                          </div>
                        </div>
                      </div>
                      <div className="gradientBg square">
                        <div className="blackBg">
                          <div>{i18n.potentialWin}</div>
                          <div className="payout">
                            {moneyFormatter(pendingBet*guess, i18n.currency, true)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row fields">
                  <span onClick={(e)=>{adjustGuess(-1)}}>
                    <YellowMinus w={25} h={25} v={"9 5 18 18"} />
                  </span>
                  
                  <Slider 
                  min={1}
                  max={201}
                  step={1}
                  value={sliderValue}
                  handleChange={handleSlider}
                  handleTouch={roundSlider}
                  />

                  <span onClick={(e)=>{adjustGuess(1)}}>
                    <YellowPlus w={25} h={25} v={"9 5 18 18"} />
                  </span>
                </div>
                <div className="instructions">
                  {i18n.winIfRollOverGuess}
                </div>
              </div>
            </div>
          </div>)
        }
      </div>
      <div className="position">
        <Button
          mainLabel = {buttonText}
          subtext = {[
            `${i18n.bet}: ${i18n.currency+pendingBet}`, 
            `${i18n.guess}: ${guess}`, 
            `${i18n.payout}: ${moneyFormatter(pendingBet*guess, i18n.currency, true)}`
          ]}
          bgColor = {buttonClass == "confirm"? "green" : "grey"}
          onClick={handleClick}
          onTouch={handleClick}
        />
      </div>
    </div>
  )
}
import { Route, Routes } from "react-router-dom"
import { WsbController } from "./controller/WsbController.js"
import { WsbTerminal } from "./terminal/WsbTerminal.js"
import { WsbLogin } from "./login/WsbLogin.js"
import { io } from "socket.io-client"
import { useEffect } from "react"

export const WallStreetBlast = (props) => {
  useEffect(()=>{
    if (window.location.href.includes("wallstreetblast")) {
      const backend = process.env.REACT_APP_NODE_ENV === "production" 
        ? process.env.REACT_APP_PROD 
        : process.env.REACT_APP_NODE_ENV === "staging"
        ? process.env.REACT_APP_STAGING
        : "http://localhost:4000"
  
      const game = io(backend+"/game")
  
      const gameState = {
        startTime: 0,
        phase: "", // preGame, inGame, postGame
        players: [],
        totalPot: 0,
        busted: 0,
        multiplier: "1.00",
        timeElapsed: 0,
        timeRemaining: 0,
        history: [],
      }
  
      // game.on("ping", (timestamp) => {
      //   data.ping = Date.now() - timestamp;
      // });
      game.on("depositSuccess", () => {
        console.log("DEPOSIT SUCCESSSFULLLLL");
      });
      game.on("gameState", (gameState) => {
        gameState = gameState;
      });
      game.on("phase", (phase) => {
        gameState.phase = phase;
        console.log(phase)
      });
      game.on("start", (startTime) => {
        gameState.startTime = startTime;
      });
      game.on("multiplier", (multiplier) => {
        gameState.multiplier = multiplier;
      });
      // game.on("balance", (balance) => {
      //   user.balance = balance / 100;
      // });
      game.on("timeElapsed", (timeElapsed) => {
        gameState.timeElapsed = timeElapsed;
      });
      game.on("timeRemaining", (timeRemaining) => {
        gameState.timeRemaining = timeRemaining;
      });
      game.on("busted", (busted) => {
        gameState.busted = busted;
      });
      game.on("players", (players) => {
        gameState.players = players;
      });
    }
  }, [])

  return (
    <Routes>
      <Route path="/login" element={
        <WsbLogin/>
      }/>
      <Route path="/*" element={
        <WsbTerminal/>
      }/>
      <Route path="/" element={
        <WsbController
          user={props.user}
          setUser={props.setUser}
          balance={props.balance}
          setBalance={props.setBalance}
        />
      }/>
    </Routes>
  )
}
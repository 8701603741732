import { useState, useEffect } from "react"
import { WsbLogo } from "../assets/WsbLogo"
import { PhoneEntry } from "./components/PhoneEntry"
import { CodeEntry } from "./components/CodeEntry"
import { fetcher } from "../../../utils/fetcher"
import { useNavigate } from "react-router-dom"
import { useAuthUser } from "react-auth-kit"

export const WsbLogin = (props) => {
  const navigate = useNavigate()
  const auth = useAuthUser()

  useEffect(()=>{
    // if (auth()) {navigate("/")} 
  })

  const [sent, setSent] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [isValid, setIsValid] = useState(false)
  const [cooldown, setCooldown] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const handleSend = async (e) => {
    if (isValid && !cooldown) {
      try {
        await fetcher("POST", null, "/2fa/send-verification", {
          phoneNumber: "+1"+phoneNumber.replace(/[^0-9]/gi, "")
        })
        setSent(true)
        setCooldown(10)
      } catch (error) {
        console.error({error: error})
      }
    }
  }

  useEffect(()=>{
    if (cooldown>0) {
      const timer = setTimeout(()=>{
        setCooldown(cooldown-1)
        clearTimeout(timer)
      }, 990)
    }
  }, [cooldown])

  return (
    <div id="wsb" className="wsbLogin">
      <div className="topHalf">
        <div className="back" onClick={(e)=>{
          setSent(false)
          setPhoneNumber("")
        }}>
          <div className="arrow"></div>
          <div className={!sent ? "invisible" : "visible"}>{"< Back"}</div>
        </div>
        <div className="wsbLogo">
          <WsbLogo w={"77.903"} h={"76"} v={"9 5 76 76"} />
        </div>
        <div className="wsbName">
          <span className="wall">Wall</span>
          <span className="street">Street</span>
          <span className="blast">Blast</span>
        </div>
      </div>
      {!sent ? (
        <PhoneEntry 
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          isValid={isValid}
          setIsValid={setIsValid}
          handleSend={handleSend}
        />) : (
        <CodeEntry
          phoneNumber={phoneNumber}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setSent={setSent}
        />)
      }
      <button 
        onClick={handleSend}
        className={!sent ? "submit" : "resend"}
      >{
        !sent ? "Next" 
          : "Didn't receive an SMS? Click to resend code" + (cooldown ? ` in ${cooldown}s` : ".")
      }</button>
    </div>
  )
}
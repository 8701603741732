export const WsbLogo = (props) => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg"
      width={props?.w ?? "77.903"} 
      height={props?.h ?? "76"} 
      viewBox={props?.v ?? "9 5 76 76"}
    >
      <g filter="url(#filter0_di_15_2944)">
      <path d="M47.0703 81.7825C67.2407 81.7825 83.5918 65.0418 83.5918 44.391C83.5918 39.8932 82.816 35.5809 81.3941 31.5866C80.3999 28.7938 76.7311 29.1175 75.7853 31.927L63.9826 66.9851C63.5061 68.4004 62.1794 69.3536 60.6861 69.3536H57.9204C56.4202 69.3536 55.089 68.3916 54.618 66.9672L48.3185 47.9145C47.2296 44.6211 42.5192 44.7761 41.6491 48.1338L34.3729 76.2118C33.9355 77.8998 34.8108 79.6692 36.4764 80.1852C39.8294 81.224 43.3861 81.7825 47.0703 81.7825Z" fill="url(#paint0_linear_15_2944)" shapeRendering="crispEdges"/>
      <path d="M71.4022 19.2106C71.8143 17.9435 71.4754 16.5411 70.4626 15.6753C64.1263 10.2592 55.97 7 47.0703 7C36.6888 7 27.319 11.4346 20.6696 18.5544C19.9273 19.3492 19.658 20.4667 19.8915 21.5289L26.2304 50.3635C27.0051 53.8875 31.9711 54.0467 32.9699 50.5796L39.9977 26.1842C40.4268 24.6946 41.7899 23.6688 43.34 23.6688H46.3656C47.8448 23.6688 49.1623 24.6044 49.6496 26.0011L56.3655 45.2474C57.4629 48.3923 61.9271 48.3448 62.9572 45.1772L71.4022 19.2106Z" fill="url(#paint1_linear_15_2944)" shapeRendering="crispEdges"/>
      <path d="M15.3809 42.8637C14.68 39.4431 10.7205 39.3337 10.5799 42.8226C10.559 43.3427 10.5484 43.8656 10.5484 44.391C10.5484 50.299 11.8867 55.8869 14.2698 60.8544C15.7764 63.9946 19.3693 62.3283 18.6701 58.9162L15.3809 42.8637Z" fill="url(#paint2_linear_15_2944)" shapeRendering="crispEdges"/>
      <path d="M47.0703 82.0325C67.3843 82.0325 83.8418 65.1742 83.8418 44.391C83.8418 39.8643 83.061 35.5236 81.6296 31.5028C81.0935 29.9966 79.8212 29.3159 78.5537 29.4066C77.2932 29.4968 76.0533 30.3472 75.5483 31.8473L63.7456 66.9053C63.3034 68.2189 62.0721 69.1036 60.6861 69.1036H57.9204C56.528 69.1036 55.2925 68.2108 54.8554 66.8887L48.5559 47.836C47.3887 44.306 42.3397 44.472 41.4071 48.0711L34.1309 76.1491C33.6635 77.9528 34.5964 79.8645 36.4024 80.424C39.7791 81.4702 43.3607 82.0325 47.0703 82.0325ZM71.6399 19.2879C72.0807 17.9328 71.7211 16.4222 70.625 15.4853C64.2457 10.0323 56.0324 6.75 47.0703 6.75C36.6157 6.75 27.181 11.2161 20.4869 18.3837C19.6842 19.2432 19.3976 20.4463 19.6474 21.5826L25.9862 50.4172C26.8166 54.1944 32.1395 54.3651 33.2101 50.6488L40.2379 26.2534C40.6362 24.8709 41.9013 23.9188 43.34 23.9188H46.3656C47.7385 23.9188 48.9613 24.7872 49.4136 26.0835L56.1294 45.3298C57.3057 48.7008 62.0908 48.6498 63.195 45.2545L71.6399 19.2879ZM15.6258 42.8135C15.2566 41.0117 14.0089 40.0233 12.7746 40.0021C12.1593 39.9915 11.5608 40.2246 11.1088 40.7047C10.6575 41.1841 10.3672 41.8931 10.3301 42.8125C10.309 43.336 10.2984 43.8622 10.2984 44.391C10.2984 50.337 11.6454 55.9619 14.0444 60.9625C14.4416 61.7903 14.9882 62.3251 15.5922 62.5857C16.197 62.8467 16.8388 62.8233 17.3999 62.5703C18.5254 62.0628 19.2834 60.6639 18.915 58.866L15.6258 42.8135Z" stroke="url(#paint3_linear_15_2944)" strokeOpacity="0.3" strokeWidth="0.5" shapeRendering="crispEdges"/>
      </g>
      <path d="M66.1942 17.3239C67.1977 16.6011 67.3298 16.867 66.3233 16.1483C63.7512 14.3119 60.7596 12.8645 57.493 11.8835C53.3837 10.6496 48.9505 10.1873 44.5401 10.5327C40.1296 10.8781 35.8614 12.022 32.0693 13.8748C29.0827 15.334 26.4551 17.2018 24.3094 19.3823C23.4277 20.2783 23.7535 21.7378 24.8826 22.2903C25.7076 22.694 26.6955 22.4645 27.3236 21.7943C29.2108 19.7805 31.5715 18.0627 34.2791 16.7397C37.5044 15.1639 40.8771 13.1195 44.6283 12.8257C48.3794 12.5319 52.3405 12.2588 55.8355 13.3083C58.8026 14.1993 61.5028 15.5427 63.7867 17.2584C64.4964 17.7916 65.4738 17.8427 66.1942 17.3239Z" fill="url(#paint4_linear_15_2944)"/>
      <path d="M55.7659 78.0276C55.7486 79.3331 57.1474 78.5149 58.3516 78.0276C61.4286 76.7823 64.4415 74.9418 67.2385 72.5905C70.7569 69.6328 73.8383 65.9471 76.2418 61.8216C78.6452 57.6961 80.3056 53.2427 81.093 48.8096C81.7132 45.3181 81.7787 41.9138 81.2935 38.7606C81.0941 37.4649 79.6957 36.924 78.5535 37.6152C77.7188 38.1202 77.3056 39.1308 77.4739 40.072C77.9796 42.8999 77.9534 45.9811 77.3911 49.1464C76.7214 52.9169 76.071 56.535 74.0268 60.0438C71.9826 63.5526 70.0627 67.0935 67.0702 69.609C64.5298 71.7447 61.1537 74.6387 58.3516 75.696C57.4807 76.0246 55.7783 77.0905 55.7659 78.0276Z" fill="url(#paint5_linear_15_2944)"/>
      <defs>
      <filter id="filter0_di_15_2944" x="0.0483704" y="0.5" width="118.043" height="119.783" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="12" dy="16"/>
      <feGaussianBlur stdDeviation="11"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0868924 0 0 0 0 0.0985833 0 0 0 0 0.379167 0 0 0 0.33 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_2944"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_2944" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="4"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.187153 0 0 0 0 0.641667 0 0 0 0 0.287146 0 0 0 0.4 0"/>
      <feBlend mode="normal" in2="shape" result="effect2_innerShadow_15_2944"/>
      </filter>
      <linearGradient id="paint0_linear_15_2944" x1="47.0701" y1="7" x2="91.1033" y2="36.1908" gradientUnits="userSpaceOnUse">
      <stop stopColor="#27E050"/>
      <stop offset="1" stopColor="#27E0BF"/>
      </linearGradient>
      <linearGradient id="paint1_linear_15_2944" x1="47.0701" y1="7" x2="91.1033" y2="36.1908" gradientUnits="userSpaceOnUse">
      <stop stopColor="#27E050"/>
      <stop offset="1" stopColor="#27E0BF"/>
      </linearGradient>
      <linearGradient id="paint2_linear_15_2944" x1="47.0701" y1="7" x2="91.1033" y2="36.1908" gradientUnits="userSpaceOnUse">
      <stop stopColor="#27E050"/>
      <stop offset="1" stopColor="#27E0BF"/>
      </linearGradient>
      <linearGradient id="paint3_linear_15_2944" x1="40.9528" y1="-9.61834" x2="80.8323" y2="63.3238" gradientUnits="userSpaceOnUse">
      <stop stopColor="#28E1B5"/>
      <stop offset="1" stopColor="#43BC5E"/>
      </linearGradient>
      <linearGradient id="paint4_linear_15_2944" x1="44.5129" y1="-5.15871" x2="46.5182" y2="24.3512" gradientUnits="userSpaceOnUse">
      <stop stopColor="#3EE98E"/>
      <stop offset="1" stopColor="#A0FFCD" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint5_linear_15_2944" x1="89.1588" y1="70.7337" x2="63.7437" y2="55.5999" gradientUnits="userSpaceOnUse">
      <stop stopColor="#3EE98E"/>
      <stop offset="1" stopColor="#A0FFCD" stopOpacity="0"/>
      </linearGradient>
      </defs>
    </svg>
  )
}
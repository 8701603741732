import { moneyFormatter } from "../../../../utils/moneyFormatter"

export const BarDiceControlScreen = (props) => {
  return (
    <div className="gradientBg">
      <div className="blackBg">
        <div className="jackpotController">
          <div className="ticketWrapper">
            <div className="jackpotTickets" onClick={()=>{props.setModalOpen(true)}}>
              {props.tickets}
            </div>
          </div>
        </div>
        <div className={`barDiceScreen ${props.cashedOut ? "starBg" : ""}`}>
          <div className={`chartText`}>
            {props.cashedOut ? (<>
              <div className="green">You Won</div>
              <div className="green">
                {moneyFormatter(props.bet*props.guess, props.currency, true)}!
              </div>
            </>) : props.bet ? (<div className="slightlySmallerText">
              <div>Bet: {moneyFormatter(props.bet, props.currency, true)}</div>
              <div>Guess: {props.guess}</div>
              <div>Potential Win: <br/>
                <span className="payout">
                  {moneyFormatter(props.bet*props.guess, props.currency, true)}
                </span>
              </div>
            </div>) : props.phase === "preGame" ? (<>
              <div>Next roll</div>
              <div className="smallGap">in {props.timeRemaining}s</div>
            </>) : (<>
              <div>Wait for</div>
              <div className="smallGap">next roll</div>
            </>)
            }
          </div>
        </div>
      </div>
    </div>
  )
}
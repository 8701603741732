export const handlePhoneNumberChange = (e, setValid, setPhone) => {
  const rawNumber = e.target.value.replace(/[^0-9]/gi, "")
  setValid(rawNumber.length>=10)
  let formattedNumber = ""
  if (rawNumber.length > 0) {
    formattedNumber = "(" + rawNumber.slice(0,3)
  }
  if (rawNumber.length > 3) {
    formattedNumber += ") " + rawNumber.slice(3,6)
  }
  if (rawNumber.length > 6) {
    formattedNumber += "-" + rawNumber.slice(6,10)
  }
  setPhone(formattedNumber)
};
import { useEffect } from "react"
import { useAuthUser } from "react-auth-kit"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { BackOfficePortal } from "./BOP/BackOfficePortal"
import { SuperAdminPanel } from "./super/SuperAdminPanel"

export const Admin = (props) => {
  const auth = useAuthUser()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(()=>{
    if (auth()?.id || props.demo) {
      navigate(
        auth()?.role === "admin" ? location.state?.redirect : "/",
        {replace: true, state: {}}
      )
    } else {
      navigate("/login", {state: {redirect: location.pathname}})
    }
  }, [auth()?.id])

  return (
    <Routes>
      <Route path="/super" element={<SuperAdminPanel/>}/>
      <Route path="/*" element={
        <BackOfficePortal
          user={props.user}
          locations={props.locations}
          selectedLocation={props.selectedLocation}
          setSelectedLocation={props.setSelectedLocation}
          venueSocket={props.venueSocket}
          backend={props.backend}
          globalSocket={props.globalSocket}
          navHeader={props.navHeader}
          jogo={props.jogo}
        />
      }/>
    </Routes>
  )
}
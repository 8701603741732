export const SmallFlame18 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="290.62 661.46 437.51 614.9 486.64 601.9 539.69 785.25 361.27 850.26 340.86 813.27 290.62 661.46"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m542.45,786.33l-9.85-33.07c39.53-59.32,26.36-137.51-40.1-168.09-18.62-17.58-36.6-34.58-54.96-52.77-10.89,10.46-25.08,29.08-41.08,28.31-3.05-.28-6.27.64-9.35,1.31-49.22,8.78-74.1,50.04-104.95,84.08-24.6-6.62-47-16.15-61.8-38.5,1.66,48.21,20.3,94.04,20.42,142.43.09,52.72,45.97,92.17,95.91,97.84,1.05.03,10.12,1.07,13.91,1.27,3.23.83,6.49.89,9.76.54l.91,1.71,7.22-2.59c41.91-1.59,79.12-16.18,112.72-40.49l61.23-21.99Zm-78.92-177.98l12.8-3.16-177.83,56.49-5.09-.2c13.13-4.31,104.97-34.35,170.12-53.13Zm-145.62,131.6l-24.11-74.17,6.01.86,55.3,168.83c-12.43-27.46-25.89-63.44-37.2-95.52Zm-12.21-74.51l178.06-56,51.83,173.4-170.5,61.15-59.39-178.55Z"/>
          <circle cx="424.41" cy="726.02" r="19.57" transform="translate(-416.3 705.35) rotate(-58.28)"/>
          <path fill="#e8c305" d="m347.07,814.95c.33-.12-2.44-8.81-6.2-19.41s-7.07-19.1-7.41-18.98c-.33.12,2.44,8.81,6.2,19.41,3.76,10.6,7.07,19.1,7.41,18.98Z"/>
          <path fill="#e8c305" d="m305.93,700.78c3.59,10.66,6.67,19.24,6.87,19.17.2-.07-2.55-8.76-6.15-19.42-3.59-10.66-6.67-19.24-6.87-19.17-.2.07,2.55,8.76,6.15,19.42Z"/>
          <path fill="#e8c305" d="m262.33,392c-4.95-11.98-10.76-22.08-20.89-29.75-4.09,10.41,7.51,26.97,20.89,29.75Z"/>
        </g>
      </g>
    </svg>
  )
}
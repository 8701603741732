import { useState } from "react";
import { useEffect } from "react";

export const Toast = ({element, id, toasts, setToasts, ms}) => {
  return <>{element}</>
};

export const Toaster = (props) => {
  const [active, setActive] = useState(false)
  const [timer, setTimer] = useState(0)

  useEffect(()=>{
    if (props.toasts.length) {
      setActive(true)

      setTimer(setTimeout(()=>{
        setActive(false)
      }, props.toasts[props.toasts.length-1].props.ms))

      return () => {clearTimeout(timer)}
    }
  }, [props.toasts])

  useEffect(()=>{
    if (!active && props.toasts.length>0) {
      props.setToasts([])
    }
  }, [active])

  // TODO: add toast interactions for click/drag events

  return (
    <div id="toaster" className={props.toasts.length?"":"hide"}>
      {props.toasts?.map((t,i)=>(
        <div key={`toast${i}`} className="toast">{t}</div>
      ))}
    </div>
  )
}

export const loadToaster = (element, toasts, setToasts, ms=3000) => {
  const key = toasts.length === 0 ? 1
    : Number(toasts[toasts.length-1].props.id) + 1

  const newToast = <Toast
    id={key}
    element={element}
    toasts={toasts}
    setToasts={setToasts}
    ms={ms}
  />

  setToasts([...toasts, newToast])
}
import { useEffect, useRef } from "react"
import { NoFlame01 } from "./noFlame/01"
import { NoFlame02 } from "./noFlame/02"
import { NoFlame03 } from "./noFlame/03"
import { NoFlame04 } from "./noFlame/04"
import { NoFlame05 } from "./noFlame/05"
import { NoFlame06 } from "./noFlame/06"
import { NoFlame07 } from "./noFlame/07"
import { NoFlame08 } from "./noFlame/08"
import { NoFlame09 } from "./noFlame/09"
import { NoFlame10 } from "./noFlame/10"
import { NoFlame11 } from "./noFlame/11"
import { NoFlame12 } from "./noFlame/12"
import { NoFlame13 } from "./noFlame/13"
import { NoFlame14 } from "./noFlame/14"
import { NoFlame15 } from "./noFlame/15"
import { NoFlame16 } from "./noFlame/16"
import { NoFlame17 } from "./noFlame/17"
import { NoFlame18 } from "./noFlame/18"
import { NoFlame19 } from "./noFlame/19"
import { NoFlame20 } from "./noFlame/20"
import { NoFlame21 } from "./noFlame/21"
import { NoFlame22 } from "./noFlame/22"
import { NoFlame23 } from "./noFlame/23"
import { NoFlame24 } from "./noFlame/24"
import { NoFlame25 } from "./noFlame/25"
import { NoFlame26 } from "./noFlame/26"
import { NoFlame27 } from "./noFlame/27"
import { NoFlame28 } from "./noFlame/28"
import { NoFlame29 } from "./noFlame/29"
import { NoFlame30 } from "./noFlame/30"
import { NoFlame31 } from "./noFlame/31"
import { NoFlame32 } from "./noFlame/32"
import { NoFlame33 } from "./noFlame/33"
import { NoFlame34 } from "./noFlame/34"
import { NoFlame35 } from "./noFlame/35"
import { NoFlame36 } from "./noFlame/36"
import { NoFlame37 } from "./noFlame/37"
import { NoFlame38 } from "./noFlame/38"
import { NoFlame39 } from "./noFlame/39"
import { NoFlame40 } from "./noFlame/40"
import { NoFlame41 } from "./noFlame/41"
import { NoFlame42 } from "./noFlame/42"
import { NoFlame43 } from "./noFlame/43"
import { NoFlame44 } from "./noFlame/44"
import { NoFlame45 } from "./noFlame/45"
import { NoFlame46 } from "./noFlame/46"
import { NoFlame47 } from "./noFlame/47"
import { NoFlame48 } from "./noFlame/48"
import { NoFlame49 } from "./noFlame/49"
import { NoFlame50 } from "./noFlame/50"
import { NoFlame51 } from "./noFlame/51"
import { NoFlame52 } from "./noFlame/52"
import { NoFlame53 } from "./noFlame/53"
import { NoFlame54 } from "./noFlame/54"
import { NoFlame55 } from "./noFlame/55"
import { NoFlame56 } from "./noFlame/56"
import { NoFlame57 } from "./noFlame/57"
import { NoFlame58 } from "./noFlame/58"
import { NoFlame59 } from "./noFlame/59"
import { NoFlame60 } from "./noFlame/60"
import { SmallFlame01 } from "./smallFlame/01"
import { SmallFlame02 } from "./smallFlame/02"
import { SmallFlame03 } from "./smallFlame/03"
import { SmallFlame04 } from "./smallFlame/04"
import { SmallFlame05 } from "./smallFlame/05"
import { SmallFlame06 } from "./smallFlame/06"
import { SmallFlame07 } from "./smallFlame/07"
import { SmallFlame08 } from "./smallFlame/08"
import { SmallFlame09 } from "./smallFlame/09"
import { SmallFlame10 } from "./smallFlame/10"
import { SmallFlame11 } from "./smallFlame/11"
import { SmallFlame12 } from "./smallFlame/12"
import { SmallFlame13 } from "./smallFlame/13"
import { SmallFlame14 } from "./smallFlame/14"
import { SmallFlame15 } from "./smallFlame/15"
import { SmallFlame16 } from "./smallFlame/16"
import { SmallFlame17 } from "./smallFlame/17"
import { SmallFlame18 } from "./smallFlame/18"
import { SmallFlame19 } from "./smallFlame/19"
import { SmallFlame20 } from "./smallFlame/20"
import { SmallFlame21 } from "./smallFlame/21"
import { SmallFlame22 } from "./smallFlame/22"
import { SmallFlame23 } from "./smallFlame/23"
import { SmallFlame24 } from "./smallFlame/24"
import { SmallFlame25 } from "./smallFlame/25"
import { SmallFlame26 } from "./smallFlame/26"
import { SmallFlame27 } from "./smallFlame/27"
import { SmallFlame28 } from "./smallFlame/28"
import { SmallFlame29 } from "./smallFlame/29"
import { SmallFlame30 } from "./smallFlame/30"
import { SmallFlame31 } from "./smallFlame/31"
import { SmallFlame32 } from "./smallFlame/32"
import { SmallFlame33 } from "./smallFlame/33"
import { SmallFlame34 } from "./smallFlame/34"
import { SmallFlame35 } from "./smallFlame/35"
import { SmallFlame36 } from "./smallFlame/36"
import { SmallFlame37 } from "./smallFlame/37"
import { SmallFlame38 } from "./smallFlame/38"
import { SmallFlame39 } from "./smallFlame/39"
import { SmallFlame40 } from "./smallFlame/40"
import { SmallFlame41 } from "./smallFlame/41"
import { SmallFlame42 } from "./smallFlame/42"
import { SmallFlame43 } from "./smallFlame/43"
import { SmallFlame44 } from "./smallFlame/44"
import { SmallFlame45 } from "./smallFlame/45"
import { SmallFlame46 } from "./smallFlame/46"
import { SmallFlame47 } from "./smallFlame/47"
import { SmallFlame48 } from "./smallFlame/48"
import { SmallFlame49 } from "./smallFlame/49"
import { SmallFlame50 } from "./smallFlame/50"
import { SmallFlame51 } from "./smallFlame/51"
import { SmallFlame52 } from "./smallFlame/52"
import { SmallFlame53 } from "./smallFlame/53"
import { SmallFlame54 } from "./smallFlame/54"
import { SmallFlame55 } from "./smallFlame/55"
import { SmallFlame56 } from "./smallFlame/56"
import { SmallFlame57 } from "./smallFlame/57"
import { SmallFlame58 } from "./smallFlame/58"
import { SmallFlame59 } from "./smallFlame/59"
import { SmallFlame60 } from "./smallFlame/60"
import { BigFlame01 } from "./bigFlame/01"
import { BigFlame02 } from "./bigFlame/02"
import { BigFlame03 } from "./bigFlame/03"
import { BigFlame04 } from "./bigFlame/04"
import { BigFlame05 } from "./bigFlame/05"
import { BigFlame06 } from "./bigFlame/06"
import { BigFlame07 } from "./bigFlame/07"
import { BigFlame08 } from "./bigFlame/08"
import { BigFlame09 } from "./bigFlame/09"
import { BigFlame10 } from "./bigFlame/10"
import { BigFlame11 } from "./bigFlame/11"
import { BigFlame12 } from "./bigFlame/12"
import { BigFlame13 } from "./bigFlame/13"
import { BigFlame14 } from "./bigFlame/14"
import { BigFlame15 } from "./bigFlame/15"
import { BigFlame16 } from "./bigFlame/16"
import { BigFlame17 } from "./bigFlame/17"
import { BigFlame18 } from "./bigFlame/18"
import { BigFlame19 } from "./bigFlame/19"
import { BigFlame20 } from "./bigFlame/20"
import { BigFlame21 } from "./bigFlame/21"
import { BigFlame22 } from "./bigFlame/22"
import { BigFlame23 } from "./bigFlame/23"
import { BigFlame24 } from "./bigFlame/24"
import { BigFlame25 } from "./bigFlame/25"
import { BigFlame26 } from "./bigFlame/26"
import { BigFlame27 } from "./bigFlame/27"
import { BigFlame28 } from "./bigFlame/28"
import { BigFlame29 } from "./bigFlame/29"
import { BigFlame30 } from "./bigFlame/30"
import { BigFlame31 } from "./bigFlame/31"
import { BigFlame32 } from "./bigFlame/32"
import { BigFlame33 } from "./bigFlame/33"
import { BigFlame34 } from "./bigFlame/34"
import { BigFlame35 } from "./bigFlame/35"
import { BigFlame36 } from "./bigFlame/36"
import { BigFlame37 } from "./bigFlame/37"
import { BigFlame38 } from "./bigFlame/38"
import { BigFlame39 } from "./bigFlame/39"
import { BigFlame40 } from "./bigFlame/40"
import { BigFlame41 } from "./bigFlame/41"
import { BigFlame42 } from "./bigFlame/42"
import { BigFlame43 } from "./bigFlame/43"
import { BigFlame44 } from "./bigFlame/44"
import { BigFlame45 } from "./bigFlame/45"
import { BigFlame46 } from "./bigFlame/46"
import { BigFlame47 } from "./bigFlame/47"
import { BigFlame48 } from "./bigFlame/48"
import { BigFlame49 } from "./bigFlame/49"
import { BigFlame50 } from "./bigFlame/50"
import { BigFlame51 } from "./bigFlame/51"
import { BigFlame52 } from "./bigFlame/52"
import { BigFlame53 } from "./bigFlame/53"
import { BigFlame54 } from "./bigFlame/54"
import { BigFlame55 } from "./bigFlame/55"
import { BigFlame56 } from "./bigFlame/56"
import { BigFlame57 } from "./bigFlame/57"
import { BigFlame58 } from "./bigFlame/58"
import { BigFlame59 } from "./bigFlame/59"
import { BigFlame60 } from "./bigFlame/60"


export const Die = (props) => {
  const animation = {
    noFlame: {
      "1": <NoFlame01 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "2": <NoFlame02 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "3": <NoFlame03 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "4": <NoFlame04 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "5": <NoFlame05 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "6": <NoFlame06 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "7": <NoFlame07 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "8": <NoFlame08 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "9": <NoFlame09 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "10": <NoFlame10 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "11": <NoFlame11 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "12": <NoFlame12 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "13": <NoFlame13 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "14": <NoFlame14 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "15": <NoFlame15 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "16": <NoFlame16 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "17": <NoFlame17 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "18": <NoFlame18 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "19": <NoFlame19 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "20": <NoFlame20 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "21": <NoFlame21 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "22": <NoFlame22 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "23": <NoFlame23 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "24": <NoFlame24 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "25": <NoFlame25 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "26": <NoFlame26 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "27": <NoFlame27 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "28": <NoFlame28 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "29": <NoFlame29 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "30": <NoFlame30 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "31": <NoFlame31 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "32": <NoFlame32 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "33": <NoFlame33 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "34": <NoFlame34 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "35": <NoFlame35 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "36": <NoFlame36 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "37": <NoFlame37 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "38": <NoFlame38 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "39": <NoFlame39 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "40": <NoFlame40 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "41": <NoFlame41 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "42": <NoFlame42 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "43": <NoFlame43 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "44": <NoFlame44 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "45": <NoFlame45 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "46": <NoFlame46 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "47": <NoFlame47 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "48": <NoFlame48 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "49": <NoFlame49 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "50": <NoFlame50 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "51": <NoFlame51 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "52": <NoFlame52 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "53": <NoFlame53 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "54": <NoFlame54 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "55": <NoFlame55 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "56": <NoFlame56 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "57": <NoFlame57 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "58": <NoFlame58 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "59": <NoFlame59 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "60": <NoFlame60 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
    },
    smallFlame: {
      "1": <SmallFlame01 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "2": <SmallFlame02 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "3": <SmallFlame03 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "4": <SmallFlame04 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "5": <SmallFlame05 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "6": <SmallFlame06 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "7": <SmallFlame07 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "8": <SmallFlame08 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "9": <SmallFlame09 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "10": <SmallFlame10 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "11": <SmallFlame11 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "12": <SmallFlame12 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "13": <SmallFlame13 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "14": <SmallFlame14 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "15": <SmallFlame15 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "16": <SmallFlame16 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "17": <SmallFlame17 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "18": <SmallFlame18 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "19": <SmallFlame19 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "20": <SmallFlame20 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "21": <SmallFlame21 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "22": <SmallFlame22 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "23": <SmallFlame23 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "24": <SmallFlame24 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "25": <SmallFlame25 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "26": <SmallFlame26 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "27": <SmallFlame27 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "28": <SmallFlame28 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "29": <SmallFlame29 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "30": <SmallFlame30 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "31": <SmallFlame31 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "32": <SmallFlame32 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "33": <SmallFlame33 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "34": <SmallFlame34 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "35": <SmallFlame35 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "36": <SmallFlame36 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "37": <SmallFlame37 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "38": <SmallFlame38 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "39": <SmallFlame39 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "40": <SmallFlame40 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "41": <SmallFlame41 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "42": <SmallFlame42 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "43": <SmallFlame43 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "44": <SmallFlame44 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "45": <SmallFlame45 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "46": <SmallFlame46 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "47": <SmallFlame47 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "48": <SmallFlame48 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "49": <SmallFlame49 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "50": <SmallFlame50 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "51": <SmallFlame51 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "52": <SmallFlame52 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "53": <SmallFlame53 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "54": <SmallFlame54 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "55": <SmallFlame55 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "56": <SmallFlame56 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "57": <SmallFlame57 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "58": <SmallFlame58 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "59": <SmallFlame59 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "60": <SmallFlame60 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>
    },
    bigFlame: {
      "1": <BigFlame01 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "2": <BigFlame02 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "3": <BigFlame03 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "4": <BigFlame04 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "5": <BigFlame05 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "6": <BigFlame06 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "7": <BigFlame07 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "8": <BigFlame08 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "9": <BigFlame09 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "10": <BigFlame10 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "11": <BigFlame11 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "12": <BigFlame12 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "13": <BigFlame13 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "14": <BigFlame14 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "15": <BigFlame15 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "16": <BigFlame16 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "17": <BigFlame17 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "18": <BigFlame18 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "19": <BigFlame19 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "20": <BigFlame20 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "21": <BigFlame21 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "22": <BigFlame22 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "23": <BigFlame23 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "24": <BigFlame24 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "25": <BigFlame25 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "26": <BigFlame26 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "27": <BigFlame27 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "28": <BigFlame28 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "29": <BigFlame29 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "30": <BigFlame30 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "31": <BigFlame31 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "32": <BigFlame32 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "33": <BigFlame33 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "34": <BigFlame34 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "35": <BigFlame35 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "36": <BigFlame36 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "37": <BigFlame37 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "38": <BigFlame38 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "39": <BigFlame39 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "40": <BigFlame40 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "41": <BigFlame41 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "42": <BigFlame42 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "43": <BigFlame43 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "44": <BigFlame44 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "45": <BigFlame45 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "46": <BigFlame46 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "47": <BigFlame47 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "48": <BigFlame48 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "49": <BigFlame49 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "50": <BigFlame50 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "51": <BigFlame51 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "52": <BigFlame52 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "53": <BigFlame53 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "54": <BigFlame54 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "55": <BigFlame55 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "56": <BigFlame56 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "57": <BigFlame57 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "58": <BigFlame58 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "59": <BigFlame59 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>,
      "60": <BigFlame60 w={props?.w ?? 100} h={props?.h ?? 100} v={props?.v ?? "250 300 100 600"}/>
    }
  }

  const frameNumber = useRef(1)
  const dieStage = useRef("noFlame")
  const frame = useRef(animation[dieStage.current][frameNumber.current])

  useEffect(()=>{
    frameNumber.current = Math.ceil((props.timeElapsed-Math.floor(props.timeElapsed))*60)
    // console.log(frameNumber.current, props.timeElapsed)
  }, [props.timeElapsed])

  useEffect(()=>{
    if (props.multiplier>=10) {
      dieStage.current = "bigFlame"
    } else if (props.multiplier>=2) {
      dieStage.current = "smallFlame"
    } else {
      dieStage.current = "noFlame"
    }
  }, [props.multiplier>=2, props.multiplier>=10])

  useEffect(()=>{
    frame.current = animation[dieStage.current][frameNumber.current]
  }, [frameNumber, dieStage])

  return (
    <div id="die" 
      className={`${props.phase!=="inGame"||props.y==0?"hide":""}`}
      style={{top: props.y-(props?.z ?? 80), left: props.x, zIndex:2}}
    >
      {animation[dieStage.current][frameNumber.current]}
    </div>
  )
}
import { moneyFormatter } from "../../../utils/moneyFormatter"
import { BarDiceLogo } from "../assets/BarDiceLogo"
import { BarDiceChart } from "./components/BarDiceChart"
import { useAuthUser } from "react-auth-kit"
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import QRCode from "react-qr-code"
import { i18nEn, i18nPt } from "./assets/i18n"
import { JackpotTotal } from "../assets/JackpotTotal"

export const BarDiceTerminal = (props) => {
  const auth = useAuthUser()
  const navigate = useNavigate()
  const location = useLocation()
  const selectedLocation = props.selectedLocation
  const i18n = props.jogo ? i18nPt : i18nEn;

  useEffect(()=>{
    console.log("terminal", location, window.location)
    if (!auth()?.id) {
      navigate("/login", {state: {redirect: location.pathname}})
    } else {
      auth().locations.find(l=>
        l===selectedLocation?.id || l===0
      ) ?? navigate("/")
    }
  }, [])

  useEffect(()=>{
    console.log(props.players)
  }, [props.players])

  return (
    <div id="barDice" className="barDiceTerminal">
      <div className="barDiceNav">
        <div className="barDiceTitle">
          <div className="barDiceLogo">
            <BarDiceLogo w={60} h={60} v={"0 0 50 50"}/>
          </div>
          <div>{"‎"} {props.jogo ? "Jogo Do Dado" : "Bar Dice 100"}</div>
        </div>
        <div className="barDiceMarquee">
          {props?.history?.map((crashpoint, i) => {
            return (
              <div key={`history${i}`} className="game">
                <span className={crashpoint >= 2 ? "yellow" : "red"}>
                  {crashpoint}
                </span>
              </div>
            )
          })}
        </div>
      </div>
      <div className="main">
        <div className="mgtLeft">
          <BarDiceChart
            multiplier={props.multiplier}
            phase={props.phase}
            timeRemaining={props.timeRemaining}
            busted={props.busted}
            timeElapsed={props.timeElapsed}
            i18nNextRoll={i18n.nextRoll}
            i18nIn={i18n.in}
            i18nRolled={i18n.rolled}
          />
          <div className="bottom">
            <div className="instructions">
              {i18n.instructions}
            </div>
            <div className="gamblingWarning">
              {i18n.warning}
            </div>
          </div>
        </div>
        <div className="mgtRight">
          <div className="barDiceLiveBets">
            <div className="headers">
              <div className="col1">{i18n.player}</div>
              <div className="col2">{i18n.guess}</div>
            </div>
            <div className="table">
              <div className="col">
                {props.players.sort((p1,p2)=>p2.guess-p1.guess).map((p, i)=>(
                  <div key={`player${i}`}
                    className={`tableRow ${
                      parseFloat(p.guess) <= parseFloat(props.multiplier) ? "yellow" 
                      : props.phase === "postGame" ? "red" 
                      : ""
                    }`}
                  >
                    <div className="col1">
                      {p.username}
                    </div>
                    <div className="col2">
                      {parseFloat(p.guess)<=parseFloat(props.multiplier) ? `${moneyFormatter(p.profit, i18n.currency, true)}` : parseFloat(p.guess).toFixed(2)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="jackpotSignage">
            <JackpotTotal w={"100%"} h={330}/>
            <div className="jackpotTextWrapper">
              <div className="jackpotFont">
                {`JACKPOT ${moneyFormatter(Math.floor(
                  (props.jackpot===0 ? localStorage.getItem("jackpot") : props.jackpot)
                  / 100
                ), i18n.currency)}`}
              </div>
            </div>
          </div>
          <div className="qrWrapper">
            <div className="qrCode">
              <QRCode
                value={window.location.origin+`/play/${selectedLocation?.id}/${selectedLocation?.path}`}
                style={{height: "auto", maxWidth: "100%", width: "60%", alignSelf: "center", padding: "5%", backgroundColor: "white", borderRadius: "12px"}}
              />
              {/* TODO PT translation */}
              <div>{i18n.scanToPlay}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
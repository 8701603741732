export const PhoneIcon = (props) => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg"
      width={props?.w ?? "58"} 
      height={props?.h ?? "58"} 
      viewBox={props?.v ?? "0 0 58 58"}
    >
      <g filter="url(#filter0_d_15_2951)">
      <path d="M23.5497 16.08C23.5254 16.059 20.7305 14.0528 19.9708 14.1905C19.6063 14.2553 19.3982 14.5036 18.981 15.0001C18.9138 15.0804 18.7523 15.2727 18.6268 15.4094C18.3629 15.3233 18.1054 15.2185 17.8563 15.0958C16.5709 14.47 15.5323 13.4314 14.9065 12.146C14.7837 11.897 14.6789 11.6395 14.5929 11.3755C14.7301 11.2495 14.9224 11.0881 15.0045 11.019C15.4992 10.6037 15.7479 10.3955 15.8123 10.0306C15.9444 9.2746 13.9433 6.4774 13.9223 6.4522C13.8315 6.32259 13.713 6.21478 13.5754 6.13657C13.4378 6.05837 13.2845 6.01172 13.1267 6C12.3156 6 10 9.00393 10 9.5098C10 9.5392 10.0425 12.5277 13.7277 16.2765C17.4727 19.9575 20.4608 20 20.4902 20C20.9961 20 24 17.6844 24 16.8733C23.9884 16.716 23.9419 16.5632 23.864 16.426C23.7862 16.2888 23.6788 16.1706 23.5497 16.08Z" fill="url(#paint0_linear_15_2951)"/>
      <path d="M17.4667 9.73333C18.209 9.73407 18.9208 10.0293 19.4457 10.5543C19.9707 11.0792 20.2659 11.791 20.2667 12.5333C20.2667 12.6571 20.3158 12.7758 20.4033 12.8633C20.4909 12.9508 20.6096 13 20.7333 13C20.8571 13 20.9758 12.9508 21.0633 12.8633C21.1508 12.7758 21.2 12.6571 21.2 12.5333C21.1989 11.5435 20.8052 10.5946 20.1053 9.8947C19.4054 9.1948 18.4565 8.80111 17.4667 8.8C17.3429 8.8 17.2242 8.84917 17.1367 8.93668C17.0492 9.0242 17 9.1429 17 9.26667C17 9.39043 17.0492 9.50913 17.1367 9.59665C17.2242 9.68417 17.3429 9.73333 17.4667 9.73333Z" fill="url(#paint1_linear_15_2951)"/>
      <path d="M17.4667 7.4C18.8276 7.40161 20.1324 7.94295 21.0947 8.90529C22.057 9.86763 22.5984 11.1724 22.6 12.5333C22.6 12.6571 22.6492 12.7758 22.7367 12.8633C22.8242 12.9508 22.9429 13 23.0667 13C23.1904 13 23.3091 12.9508 23.3967 12.8633C23.4842 12.7758 23.5333 12.6571 23.5333 12.5333C23.5315 10.9249 22.8917 9.38292 21.7544 8.2456C20.6171 7.10828 19.0751 6.46852 17.4667 6.46667C17.3429 6.46667 17.2242 6.51583 17.1367 6.60335C17.0492 6.69087 17 6.80957 17 6.93333C17 7.0571 17.0492 7.1758 17.1367 7.26332C17.2242 7.35083 17.3429 7.4 17.4667 7.4Z" fill="url(#paint2_linear_15_2951)"/>
      </g>
      <defs>
      <filter id="filter0_d_15_2951" x="0" y="0" width="58" height="58" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="12" dy="16"/>
      <feGaussianBlur stdDeviation="11"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0868924 0 0 0 0 0.0985833 0 0 0 0 0.379167 0 0 0 0.33 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_2951"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_2951" result="shape"/>
      </filter>
      <linearGradient id="paint0_linear_15_2951" x1="17.0211" y1="-3.02899" x2="26.9777" y2="0.765856" gradientUnits="userSpaceOnUse">
      <stop stopColor="#27E050"/>
      <stop offset="1" stopColor="#53FFE0"/>
      </linearGradient>
      <linearGradient id="paint1_linear_15_2951" x1="17.0211" y1="-3.02899" x2="26.9777" y2="0.765856" gradientUnits="userSpaceOnUse">
      <stop stopColor="#27E050"/>
      <stop offset="1" stopColor="#53FFE0"/>
      </linearGradient>
      <linearGradient id="paint2_linear_15_2951" x1="17.0211" y1="-3.02899" x2="26.9777" y2="0.765856" gradientUnits="userSpaceOnUse">
      <stop stopColor="#27E050"/>
      <stop offset="1" stopColor="#53FFE0"/>
      </linearGradient>
      </defs>
    </svg>
  )
}
import "./App.scss";
import { useEffect, useRef, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useAuthUser, useSignOut, useAuthHeader } from "react-auth-kit";
import { game, server, socket } from "./utils/sockets";
import { fetcher } from "./utils/fetcher";
import { WallStreetBlast } from "./games/WallStreetBlast/WallStreetBlast";
import { BarDice100 } from "./games/BarDice100/BarDice100";
import { Cashier } from "./hub/cashier/Cashier";
import { Admin } from "./hub/admin/Admin";
import { Account } from "./hub/account";
import { Support } from "./hub/account/Support";
import { RadSoftwareBackground } from "./hub/_assets/radsoftware-bg";
import { Toaster } from "./utils/toast";
// import GoogleOAuthProvider from './games/BarDice100/login/components/GoogleSignIn/GoogleOAuthProvider'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { Helmet } from "react-helmet";
import { Modal } from "./utils/modal";

// DATA
const data = {
  user: {
    username: null,
    at: null,
    history: [],
    role: "player",
    id: null,
    phoneNumber: null,
    gameHistory: null,
    balance: 0,
  },
  mobileDevice:
    navigator?.maxTouchPoints ||
    navigator?.msMaxTouchPoints ||
    (window.matchMedia && matchMedia("(pointer:coarse)")?.matches) ||
    window?.orientation ||
    /\b(BlackBerry|webOS|iPhone|IEMobile|Android|Windows Phone|iPad|iPod)\b/i.test(
      navigator.userAgent
    ),
  demo: (window.location.href.includes("demo.") || process.env.REACT_APP_DEMO) && process.env.REACT_APP_NODE_ENV !== "production",
  jogo: window.location.href.includes("jogododado")
};

function App() {
  const auth = useAuthUser();
  const signOut = useSignOut();
  const authHeader = useAuthHeader();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log(authHeader(), auth())
  }, [authHeader()])

  const [user, setUser] = useState({});
  const [balance, setBalance] = useState(0);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(undefined);
  const [toasts, setToasts] = useState([])

  const backend = useRef(
    process.env.REACT_APP_NODE_ENV === "production"
      ? process.env.REACT_APP_PROD // TODO: dedicated DB server
      : process.env.REACT_APP_NODE_ENV === "staging"
      ? process.env.REACT_APP_STAGING
      : "http://localhost:4000"
  );

  const globalSocket = useRef();
  useEffect(() => {
    if (auth()?.id) {
      globalSocket.current = socket("/global");
      globalSocket.current.on("connect", () => {
        console.log("global connected");
        data.user.username = `guest-${Math.round(Math.random() * 100000)}`;
      });
      globalSocket.current.on("disconnect", () => {
        console.log("disconnected from global");
        globalSocket.current?.disconnect(true);
        globalSocket.current?.off();
        //? error screen to overwrite blast?
      });
      globalSocket.current.on("balanceUpdate", (newBalance) => {
        console.log("balance update", newBalance);
        setBalance(newBalance / 100);
      });
      globalSocket.current.on("forceNavigate", (route) => {
        navigate(route);
      });
      globalSocket.current.on("forceSignOut", () => {
        signOut();
        window.location.reload();
      });
    }

    return () => {
      console.log("destroy sockets");
      globalSocket.current?.disconnect(true);
      globalSocket.current?.off();
    };
  }, [auth()?.id]);

    // handle page navigation
    useEffect(()=>{
      if (auth()?.id && balance === 0) {
        globalSocket.current.emit("register", location.pathname.includes("/admin") ? "admin" + auth().id : auth().id);
        if (!location.pathname.includes("/admin")) {
          globalSocket.current.emit("balanceUpdate", auth()?.id);
        }
      }
    }, [window.location.href])

  const venueSocket = useRef();
  useEffect(() => {
    if (selectedLocation?.id) {
      venueSocket.current = socket(`/venue-${selectedLocation?.id}`);
      venueSocket.current.on("connect", () => {
        console.log("venue established", selectedLocation);
      });
      venueSocket.current.on("disconnect", () => {
        console.log("venue disconnected")
      });
      venueSocket.current.on("venueUpdate", (update) => {
        const oldVenue = locations.find(v => v.id === update.id)
        console.log("update", locations, update)
        if (oldVenue) {
          setLocations(locations.map(v => v.id === update.id ? update : v))
          if (selectedLocation.id === update.id) {
            setSelectedLocation(update)
            console.log("venue updated", oldVenue, update)
          }
        }
      })
    }

    if (!selectedLocation && location.state?.venue) {
      setSelectedLocation(location.state?.venue);
    }
    if (selectedLocation && location.state?.venue !== selectedLocation) {
      if (!location.state) {
        location.state = {};
      }
      location.state.venue = selectedLocation;
      console.log("location venue", location);
    }

    return () => {
      venueSocket.current?.disconnect();
    };
  }, [selectedLocation]);

  // CAPTURE QR METADATA
  useEffect(() => {
    if (location.pathname.includes("/play")) {
      console.log("QR captured")
      const path = location.pathname.match(/\/play\/(\d+)\/(\w+)/);
      const venue = {
        id: Number(path[1]),
        path: path[2],
      };
      localStorage.setItem("venue", JSON.stringify({
        id: venue.id,
        expires: Date.now() + 12 * 60 * 60 * 1000
      }))
      console.log("qr", venue, localStorage.getItem("venue"))
      navigate(auth()?.id ? "/" : "/login");
    } else {
      console.log("qr venue check", localStorage.getItem("venue"))
    }
  }, []);

  // POST LOGIN HOOK
  useEffect(() => {
    if (auth()?.id) {
      setUser({
        id: auth().id || null,
        username: auth().username 
          ?? auth().firstName 
          ?? `Guest ${auth().id}`,
        phoneNumber: auth().phoneNumber ?? "",
        email: auth().email,
        role: auth().role,
        locations: auth().locations,
        details: auth().details,
        settings: auth().settings,
      });

      // globalSocket.current.emit("register", location.pathname.includes("/admin") ? "admin" + auth().id : auth().id);
      // globalSocket.current.emit("balanceUpdate", auth()?.id);

      (async () => {
        await fetcher(
          "GET",
          authHeader(),
          backend.current + "/locations?active=true&attributes=id_name_path_cashEnabled&order=id-ASC"
        )
          .then((data) => {
            setLocations(data);
            console.log(localStorage.getItem("venue"))
            const venue = JSON.parse(localStorage.getItem("venue"))
            let currentVenue
            let path = window.location.href.match(/com.*\/(\w+)$/)
            console.log(path)
            if (auth()?.role === "admin" && path) {
              path = path[1].toLowerCase()
              currentVenue = data.find(l => l.path === path)
            } else if (venue?.expires > Date.now()) {
              currentVenue = data.find(l => l.id === venue?.id)
            } else {
              // TODO: route players with expired QR to some "please scan" page
            }
            setSelectedLocation(currentVenue)
            localStorage.setItem("venue", JSON.stringify({
              id: currentVenue?.id,
              expires: currentVenue?.id ? Date.now() + 12 * 60 * 60 * 1000 : 0
            }))
          })
          .catch((error) => {
            console.log(error);
          });
      })();
    }
    // eslint-disable-next-line
  }, [auth()?.id]);



  useEffect(() => {
    document
      .querySelector("#root")
      .style.setProperty(
        "height",
        document.documentElement.clientHeight + "px"
      );
    // console.log("useEffect innerHeight", window.innerHeight)
  }, [document.documentElement.clientHeight]);  

  const [jackpotValue, setJackpotValue] = useState(0)
  const [jackpotTicketCount, setJackpotTicketCount] = useState(0)
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(<></>);
  
  return (
    <>
      <Helmet>
        <title>
          {window.location.href.includes("bardice") ? "Bar Dice 100"
            : window.location.href.includes("jogododado") ? "JogoDoDado"
              : "Wall Street Blast"
          }
        </title>
        <link rel="icon" type="image/png" href={
          window.location.href.includes("bardice") || window.location.href.includes("jogododado") ? "/barDiceFavicon.png"
            : "/icon.png"
        } />
        <link rel="apple-touch-icon" sizes="180x180" href={
          window.location.href.includes("bardice") || window.location.href.includes("jogododado") ? "/barDiceFavicon.png"
            : "/icon.png"
        } />
      </Helmet>
      <GoogleOAuthProvider clientId="302612497388-ds5todt0n0ooqlb87bhrs510pc9s8e85.apps.googleusercontent.com">
        <Routes>
          <Route
            path="/admin/*"
            element={
              <Admin
                user={user}
                locations={locations}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                backend={backend}
                venueSocket={venueSocket}
                globalSocket={globalSocket}
                demo={data.demo}
                jogo={data.jogo}
                jackpotValue={jackpotValue}
              />
            }
          />
          <Route
            path="/cashier/*"
            element={
              <Cashier
                user={user}
                balance={balance}
                locations={locations}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                backend={backend}
                venueSocket={venueSocket}
                globalSocket={globalSocket}
                demo={data.demo}
                jogo={data.jogo}
                jackpotValue={jackpotValue}
                modalOpen={modalOpen}
                modalData={modalData}
                setModalOpen={setModalOpen}
                setModalData={setModalData}
              />
            }
          />
          <Route
            path="/account"
            element={
              <Account
                username={auth()?.phoneNumber}
                balance={balance}
                data={data}
                user={user}
                setUser={setUser}
                backend={backend}
                demo={data.demo}
                jogo={data.jogo}
                jackpotValue={jackpotValue}
                selectedLocation={selectedLocation}
                modalOpen={modalOpen}
                modalData={modalData}
                setModalOpen={setModalOpen}
                setModalData={setModalData}
              />
            }
          />
          <Route
            path="/account/support"
            element={
              <Support
                username={auth()?.phoneNumber}
                balance={balance}
                data={data}
                user={user}
                demo={data.demo}
                jogo={data.jogo}
                jackpotValue={jackpotValue}
                selectedLocation={selectedLocation}
                modalOpen={modalOpen}
                modalData={modalData}
                setModalOpen={setModalOpen}
                setModalData={setModalData}
              />
            }
          />
          <Route
            path="/*"
            element={
              window.location.href.includes("wallstreetblast.com") ? (
                <WallStreetBlast
                  user={user}
                  setUser={setUser}
                  balance={balance}
                  setBalance={setBalance}
                  demo={data.demo}
                  jogo={data.jogo}
                />
              ) : window.location.href.includes("bardice100.com") || window.location.href.includes("jogododado.com") ? (
                <BarDice100
                  user={user}
                  setUser={setUser}
                  balance={balance}
                  setBalance={setBalance}
                  selectedLocation={selectedLocation}
                  setSelectedLocation={setSelectedLocation}
                  demo={data.demo}
                  jogo={data.jogo}
                  toasts={toasts}
                  setToasts={setToasts}
                  currency={process.env.REACT_APP_CURRENCY}
                  setJackpotValue={setJackpotValue}
                  modalOpen={modalOpen}
                  modalData={modalData}
                  setModalOpen={setModalOpen}
                  setModalData={setModalData}
                />
              ) : (
                <div>Error 404</div>
              )
            }
          />
        </Routes>
        <Modal modalOpen={modalOpen} setModalOpen={setModalOpen} element={modalData}/>
        <Toaster toasts={toasts} setToasts={setToasts}/>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
export const BigFlame15 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="284.19 683.02 315.59 636.4 487.67 588.39 552.97 758.16 383.96 823.84 338.25 836.14 284.19 683.02"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m267.08,365.01c6.65-28.95-34.5-35.67-49.8-55.14,2.13,42.15-24.54,64.32-45.79,96.85-33.62,61.89,13.83,82.55,52.99,118.49-28.44-87.57,26.57-88.19,42.6-160.2Z"/>
          <path fill="#e8c305" d="m316.44,470.41c2.7,4.59,5.42,9.25,9.71,14.06,2.8-44.52,29.75-37.79-51.99-54.36,11.13,24.27,32.01,22.43,42.28,40.3Z"/>
          <path fill="#e8c305" d="m87.21,322.77c-.2.9-.4,1.8-.63,2.83-14.75-.58-4.12,13.89-6.46,22.78,11.6-6.26,9.47-14.86,7.77-25.66-.23.02-.46.03-.68.05Z"/>
          <path fill="#e8c305" d="m555.32,758.34l-18.32-47.94c9.72-38.55-10.74-74.62-38.25-100.07l-9.12-23.86c-.31-.8-1.17-1.24-2-1.01l-15.86,4.42c-47.44-30.47-105.35,3.15-153.3-23.52,2.53,20.63,11.47,36.68,28.06,48.78-35.23,5.23-96.88,13.87-108.82-31.97-20.95,76.74,16.55,211.83,96.6,242.7l4.06,11.48c.29.83,1.18,1.29,2.03,1.04l18.92-5.52c39.69,10.39,119.84-18.95,140.15-51.38l54.89-21.02c.86-.33,1.28-1.29.96-2.14Zm-215.2,74.77c-.85.27-1.76-.19-2.06-1.03l-52.25-146.51c-.18-.5-.11-1.05.19-1.49l22.02-32.35c2.28-3.13,4.55-6.24,6.81-9.35.79-.24,1.73.12,2.05,1.01l63.36,175.39c.32.89-.16,1.87-1.07,2.15l-39.06,12.19Zm207.77-75.66l-158.29,59.74c-.87.33-1.83-.12-2.15-.99l-64.07-177.22c-.33-.91.18-1.9,1.11-2.16l160.49-45.19c.83-.23,1.7.2,2,1.01l61.88,162.67c.33.86-.11,1.82-.97,2.14Z"/>
          <ellipse cx="439.72" cy="698.38" rx="18.5" ry="19.85" transform="translate(-205.23 183.47) rotate(-19.2)"/>
          <path fill="#e8c305" d="m299.83,696.59c-2.27.79-1.53,8.82,1.64,17.94,3.18,9.12,7.59,15.87,9.86,15.08,2.27-.79,1.53-8.82-1.64-17.94-3.18-9.12-7.59-15.87-9.86-15.08Z"/>
          <path fill="#e8c305" d="m347.36,762.84c-2.85.99-2.29,10.08,1.27,20.3,3.56,10.22,8.76,17.69,11.61,16.7s2.29-10.08-1.27-20.3c-3.56-10.22-8.76-17.69-11.61-16.7Z"/>
        </g>
      </g>
    </svg>
  )
}
import { useEffect, useState } from "react";
import { useAuthUser, useSignOut, useAuthHeader } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

export const WsbTerminal = (props) => {
  const auth = useAuthUser();
  const navigate = useNavigate();

  useEffect(()=>{
    if (!auth()?.role?.includes("admin")) {
      navigate("/admin/login", {state: {redirect: "/mgt"}})
    }
  }, [auth()])

  return (
    <div id="wsb">
      Terminal
    </div>
  )
}
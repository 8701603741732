export const SmallFlame17 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="290.84 670.49 308.89 651.85 489.32 598.13 545.4 777.2 367.68 841.4 339.88 819.81 290.84 670.49"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m537.78,742.86c28.82-54.17,15.31-125.56-42.24-153.84-21.89-13.67-40.8-32.37-61.21-48.12-11.75,7.36-23.04,14.39-34.29,21.5-20.02,5.46-41.15,7.57-59.65,18.41-41.83,16.97-46.77,45.28-70.06,78-23.35-4.3-39.8-16.56-48.34-38.94-8.21,44.24,13.32,85.66,21.74,127.9,10.84,122.93,178.89,123.29,249.54,50.74l55.7-20.26-11.18-35.4Zm-164.7,93.33l-59.39-181.67,173.38-52.87,55.07,172.96-169.07,61.58Zm-12.51-2.91l-14.71-10.07c-2.26-2.14-4.08-4.5-5.14-7.19-15.71-48.64-31.6-96.9-47.45-145.54l14.27-13.42,58.51,179.66c-1.74-1.17-3.59-2.3-5.48-3.43Z"/>
          <circle cx="431.5" cy="716.62" r="19.64" transform="translate(-153.1 118.31) rotate(-13.28)"/>
          <path fill="#e8c305" d="m302.45,690.24c-1.06.34.5,8.14,3.49,17.43,2.98,9.29,6.26,16.54,7.32,16.2,1.06-.34-.5-8.14-3.49-17.43-2.98-9.29-6.26-16.54-7.32-16.2Z"/>
          <path fill="#e8c305" d="m351.8,809.39c1.4-.46-.23-9.2-3.65-19.52s-7.32-18.31-8.72-17.84c-1.4.46.23,9.2,3.65,19.52s7.32,18.31,8.72,17.84Z"/>
          <path fill="#e8c305" d="m279.18,417.17c-8.53-16.1-17.4-31.04-31.52-43.05-3.47,7.4-3.25,13.78,1.1,19,8.08,8.46,18.16,22.57,30.42,24.06Z"/>
        </g>
      </g>
    </svg>
  )
}
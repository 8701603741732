import { useEffect, useRef, useState } from "react"
import { useAuthHeader, useAuthUser } from "react-auth-kit"
import { useLocation, useNavigate } from "react-router-dom"
import RadSoftLogo from "../../_assets/RadSoftLogo.png"
import { fetcher } from "../../../utils/fetcher"
import { moneyFormatter } from "../../../utils/moneyFormatter"
import { phoneFormatter } from "../../../utils/phoneFormatter"
import { Modal } from "../../../utils/modal"
import { SearchIcon } from "./assets/SearchIcon"
import { GreyLeftChevron } from "./assets/GreyLeftChevron"
import { CashEnabled } from "./assets/CashEnabled"
import { CashDisabled } from "./assets/CashDisabled"
import { i18nEn, i18nPt } from "./assets/i18n"

export const BackOfficePortal = (props) => {
  const auth = useAuthUser()
  const authHeader = useAuthHeader()
  const navigate = useNavigate()
  const location = useLocation()
  const user = props.user
  const backend = props.backend
  const locations = props.locations
  const setSelectedLocation = props.setSelectedLocation
  const selectedLocation = props.selectedLocation
  const venueSocket = props.venueSocket
  const globalSocket = props.globalSocket
  const i18n = props.jogo ? i18nPt : i18nEn
  const [requests, setRequests] = useState([])
  const [filtered, setFiltered] = useState([])
  const [modalData, setModalData] = useState(<></>);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [requestAction, setRequestAction] = useState(undefined)
  const [trackedSince, setTrackedSince] = useState(Number(localStorage.getItem("eventStart")))
  const [totalWithdraw, setTotalWithdraw] = useState(Number(localStorage.getItem("total_withdraw") ?? 0))
  const [totalDeposit, setTotalDeposit] = useState(Number(localStorage.getItem("total_deposit") ?? 0))

  useEffect(()=>{
    if (selectedLocation) {
      if (!!user.locations?.find(l=>
        l?.id===0 || l?.id===selectedLocation?.id
      )) {
        navigate("/")
      }
    }
  }, [selectedLocation])

  useEffect(() => {
    if (auth()?.id && selectedLocation) {
      (async () => {
        await fetcher("GET", authHeader(), 
          backend.current+`/request/transaction/admin/${auth()?.id}?locationId=${selectedLocation.id}&status=pending&attributes=id_locationId_userId_amount_action_actionCode&include=User-username_phoneNumber`, {
            locationId: Number(selectedLocation?.id),
            authId: auth()?.id,
          }
        ).then((data)=>{
          console.log(data)
          setRequests([...data])
          venueSocket.current.on("addRequest", (request)=>{
            setRequests([...requests, JSON.parse(request)])
          })
          venueSocket.current.on("removeRequest", (requestId)=>{
            setRequests(requests.map(r=>r.id!==requestId))
          })
          venueSocket.current.on("disconnect", ()=>{
            console.log("admin panel disconnected")
          })
        })
      })()
    }
  }, [selectedLocation]);

  useEffect(()=>{
    setFiltered([...requests
      .filter(r=>!requestAction || r?.action===requestAction)
      .filter(r=>r?.actionCode?.includes(searchTerm.toUpperCase()))
    ])
  }, [requests, requestAction, searchTerm])

  const handleResponse = async (requestId, status, userId, action=null, amount=null) => {
    try {
      await fetcher("PUT", authHeader(),
        backend.current+"/request/transaction/" + requestId,
        { id: requestId, status, adminId: auth()?.id }
      ).then((data)=>{
        venueSocket.current.emit("resolveRequest", requestId)
        globalSocket.current.emit("balanceUpdate", userId)
        globalSocket.current.emit("forceNavigate", userId, "/")
        setModalOpen(false) // TODO: loading icon indicator

        // TODO: better stat tracking
        if (status === "approved") {
          console.log("stat", localStorage.getItem(`total_${action}`), "amount", amount)
          let updated = Number(localStorage.getItem(`total_${action}`) ?? 0) + amount
          let lastUpdated = Number(localStorage.getItem("lastUpdated"))
          let eventStart = Number(localStorage.getItem("eventStart"))
          console.log("lastUpdated", lastUpdated, Date.now())
          if (!eventStart || lastUpdated+43200000 < Date.now()) {
            console.log("stat reset")
            eventStart = Date.now()
            localStorage.setItem("eventStart", eventStart)
            localStorage.setItem(`total_${action}`, updated)
            localStorage.setItem(`total_${action==="deposit"?"withdraw":"deposit"}`, 0)
            setTrackedSince(eventStart)
          }
          action==="deposit" ? setTotalDeposit(updated) : setTotalWithdraw(updated)
          localStorage.setItem("lastUpdated", Date.now())
        }
      })
    } catch (err) {
      console.log({ error: err });
    }
  }

  const handleCashStatus = async (e) => {
    await fetcher("PUT", authHeader(), 
      backend.current+`/locations/${selectedLocation?.id}`, {
      authId: auth()?.id,
      cashEnabled: !selectedLocation?.cashEnabled
    }).then((data)=>{
      // check success
      // websocket trigger edit of location through all venue connections
      const updatedVenue = selectedLocation
      updatedVenue.cashEnabled = !selectedLocation?.cashEnabled
      console.log(updatedVenue)
      venueSocket.current.emit("venueUpdate", updatedVenue)
    }).catch((err)=>{})
  }

  const handleClose = (e) => {
    e.preventDefault()
    setModalOpen(false)
  }

  const handleModal = (r) => {
    if (!modalOpen) {
      console.log("test admin modal")
      let capAction = r.action
        .split('')
        .map((l,i)=>i?l:l?.toUpperCase())
        .join("")
      setModalOpen(true)
      setModalData(
        <div id="requestModal">
          <div className="row cancel" onClick={handleClose}>
            <span><GreyLeftChevron w={10} h={13} v={"0 0 10 13"}/> Cancel</span>
          </div>
          <div className="requestAmount">
            <span>{`${capAction} Request: `}</span>
            <span className="green">
              {moneyFormatter(r.amount, i18n.currency, false)}
            </span>
          </div>
          <div className="row">
            {r.actionCode.split("").map((l,i)=>(
              <div key={`code${i}`}>
                <div className="codePart">{l}</div>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="resolveRequest-green" 
              onClick={(e)=>{handleResponse(r.id, "approved", r.userId, r.action, r.amount)}}
            >
              Process {capAction}
            </div>
            <div className="resolveRequest-red"
              onClick={(e)=>{handleResponse(r.id, "canceled", r.userId)}}
            >
              Void {capAction}
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div id="backOfficePortal">
      <nav className="row">
        <img src={RadSoftLogo} onClick={(e)=>{navigate("/")}}/>
        {/* <div className="colCenter">
          <div className="row">
            <span className="mr-1vw">Cash?</span> 
            <span onClick={handleCashStatus}>   
              {selectedLocation?.cashEnabled 
                ? <CashEnabled/> 
                : <CashDisabled/>
              }
            </span>
          </div>
        </div> */}
        <div className="colCenter">
          @{selectedLocation?.name}
        </div>
      </nav>
      <div>
        <div className="stats">
          <div>ND: {totalDeposit}</div>
          <div>NW: {totalWithdraw}</div>
          <div>NGR: {totalDeposit-totalWithdraw}</div>
          {/* <div className="hide">TB: {60}</div> */}
        </div>
        <div className={trackedSince ? "eventStart" : "hide"}>
          {`Since ${new Date(trackedSince)?.toLocaleTimeString([], {
            hour12: true, hour: "numeric", minute: "2-digit"
          })} ${new Date(trackedSince)?.toLocaleDateString([], {
            dateStyle: "short"
          })}`}
        </div>
      </div>
      <div className="categoryFilter">
        {[undefined, "deposit", "withdraw"].map((action,i)=>(
          <div 
            key={`transactionType${i}`}
            className={`${action===requestAction ? "active" : ""}`}
            onClick={(e) => {setRequestAction(action)}}
          >
            {action
              ?.split("")
              ?.map((l,i)=>i?l:l?.toUpperCase())
              ?.concat(["s"])
              ?.join("") 
              ?? "All"
            }
          </div>
        ))}
      </div>
      <div className="searchBar">
        <div className="searchIcon">
          <SearchIcon w={25} h={25} v={"5 0 25 25"}/>
        </div>
        <input type="text" 
          autoComplete="off"
          placeholder="Search by code"
          value={searchTerm}
          onChange={(e)=>{setSearchTerm(e.target.value.toUpperCase())}}
          inputMode="search"
        />
      </div>
      <div className="requestTable">
        <div className="requestHeaders">
          <div className="reqCol1">Amount</div>
          <div className="reqCol2">User</div>
          <div className="reqCol3">Code</div>
        </div>
        <div className="requestList">
          {filtered?.map((r, i)=>(
            <div key={`request${i}`}
              className={`row requestLine-${r.action}`}
              onClick={(e)=>{handleModal(r)}}
            >
              <div className="reqCol1">
                {moneyFormatter(r.amount, i18n.currency, false)}
              </div>
              <div className="reqCol2">
                {r.User?.username ?? phoneFormatter(r.User?.phoneNumber, false)}
              </div>
              <div className="reqCol3">{r.actionCode}</div>
            </div>
          ))}
        </div>
      </div>
      <Modal modalOpen={modalOpen} setModalOpen={setModalOpen} element={modalData}/>
    </div>
  )
}
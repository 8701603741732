export const SmallFlame15 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="283.79 682.91 315.2 636.3 487.27 588.28 552.57 758.06 383.57 823.74 337.86 836.03 283.79 682.91"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill="#e8c305" d="m555.32,758.34l-14.77-38.65c14.79-58.48-14.02-107.35-48.64-127.26l-2.28-5.97c-.31-.8-1.17-1.24-2-1.01l-5.42,1.51c-17.94-9.97-38.18-16.87-55.57-28.57-1.73-1.04-3.4-1-5.26-.61-19.07,3.04-36.18,12.17-54.47,17.53-27.25,6.83-82.56,15.5-96.3,40.86-13.12,20.39-24.43,41.65-30.21,65.11-12.3-4.2-21.11-14.97-28.18-25.29-11.39,44.99,15.32,92.13,41.06,128.04,68.97,88.65,193.23,76.9,259.26-7.54l41.82-16.01c.86-.33,1.28-1.29.96-2.14Zm-176.14,62.59l-39.06,12.19c-.85.27-1.76-.19-2.06-1.03l-52.25-146.51c-.18-.5-.11-1.05.19-1.49l25.47-37.43c1.05-1.4,2.09-2.8,3.14-4.2.83-.39,1.92-.05,2.27.93l63.36,175.39c.32.89-.16,1.87-1.07,2.15Zm168.71-63.47l-158.29,59.74c-.87.33-1.83-.12-2.15-.99l-64.07-177.22c-.33-.91.18-1.9,1.11-2.16l160.49-45.19c.83-.23,1.7.2,2,1.01l61.88,162.67c.33.86-.11,1.82-.97,2.14Z"/>
          <ellipse cx="439.72" cy="698.38" rx="18.5" ry="19.85" transform="translate(-205.23 183.47) rotate(-19.2)"/>
          <path fill="#e8c305" d="m299.83,696.59c-2.27.79-1.53,8.82,1.64,17.94,3.18,9.12,7.59,15.87,9.86,15.08,2.27-.79,1.53-8.82-1.64-17.94-3.18-9.12-7.59-15.87-9.86-15.08Z"/>
          <path fill="#e8c305" d="m347.36,762.84c-2.85.99-2.29,10.08,1.27,20.3,3.56,10.22,8.76,17.69,11.61,16.7s2.29-10.08-1.27-20.3c-3.56-10.22-8.76-17.69-11.61-16.7Z"/>
          <path fill="#e8c305" d="m254.71,410.27c12.71,8.84,22.84,18.81,37.56,25.01-9.72-22.59-21.22-46.1-48.62-47.02.25,9.63,4.1,16.71,11.06,22.01Z"/>
        </g>
      </g>
    </svg>
  )
}
export const NoFlame18 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="291.04 661.39 437.93 614.83 487.06 601.83 540.11 785.17 361.69 850.18 341.28 813.2 291.04 661.39"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill="#e8c305" d="m487.46,601.65l-39.13,10.36-5.49,1.45,33.5-8.28-177.83,56.49-7.46-.29,50.2,152.39,20.04,37.62,181.17-65.07-54.99-184.68Zm-145.97,210.84l-47.69-146.71,6.01.86,57.66,176.03-15.97-30.18Zm-35.79-147.05l178.06-56,51.83,173.4-170.5,61.15-59.39-178.55Z"/>
          <circle cx="424.41" cy="726.02" r="19.57"/>
          <ellipse cx="340.27" cy="795.76" rx=".64" ry="20.36" transform="translate(-246.27 159.37) rotate(-19.51)"/>
          <ellipse cx="306.3" cy="700.66" rx=".38" ry="20.36" transform="translate(-207.88 134.65) rotate(-18.64)"/>
        </g>
      </g>
    </svg>
  )
}
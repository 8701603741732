import { useEffect, useRef } from "react"
import { useSignIn } from "react-auth-kit"
import { fetcher } from "../../../../utils/fetcher"
import { useLocation, useNavigate } from "react-router-dom"

export const CodeEntry = (props) => {
  const { phoneNumber, isLoading, setIsLoading, setSent } = props
  const inputRefs = [useRef(), useRef(), useRef(), useRef()]
  const signIn = useSignIn()
  const navigate = useNavigate()
  const location = useLocation()

  const handleCheck = async () => {
    if (isLoading) return; // Prevent multiple requests while loading
    setIsLoading(true);
    try {
      const newCode = inputRefs.map(ref=>ref.current.value).join("")
      let response = await fetcher("POST", null, "/2fa/check-verification", {
        phoneNumber: "+1"+phoneNumber.replace(/[^0-9]/gi, ""),
        code: newCode
      })
      if (signIn({
        token: response.token,
        expiresIn: 1440,
        tokenType: "Bearer",
        authState: response.state
      })) {
        navigate(location?.state?.redirect ?? "/")
        setSent(false)
      } else {
        console.log("Error signing in")
      }
    } catch (error) {
      console.error({ error: error });
      // Go back to start of input
      if (inputRefs.length > 0) {
        inputRefs[0].current.focus();
      }
    } finally {
      setIsLoading(false);
      inputRefs.forEach((inputRef) => (inputRef.current.value = ""));
      console.log("done")
    }
  }

  const handleInputPaste = (e, index) => {
    e.preventDefault(); // Prevent default paste behavior
    const pasteData = e.clipboardData.getData("text/plain").replace(/\D/g, ""); // Extract digits

    for (let i = 0; i < inputRefs.length; i++) {
      if (pasteData.length > i) {
        inputRefs[i].current.value = pasteData[i]; // Fill inputs from left to right
      } else {
        inputRefs[i].current.value = ""; // Clear any remaining inputs
      }
    }
    handleCheck(); // Optionally trigger verification here
  };

  const handleKeyUp = (e, index) => {
    const { value } = e.target;

    // Move focus to the previous input if the current input is empty and the Backspace key is pressed
    if (value === "" && index > 0 && e.keyCode === 8) {
      inputRefs[index - 1].current.focus();
    }
  };

  const handleChange = (e, index) => {
    const { value } = e.target;

    // Check if the input value is a number
    if (!isNaN(value) && value !== "") {
      // Move focus to the next input if available
      if (index < inputRefs.length - 1) {
        inputRefs[index + 1].current.focus();
      } else {
        handleCheck();
      }
    }

    // If the input value is a number and there are remaining inputs, fill them
    if (!isNaN(value) && value !== "" && value.length === 4) {
      for (let i = 0; i < inputRefs.length; i++) {
        inputRefs[i].current.value = value[i] || ""; // Fill inputs from left to right
      }
      handleCheck(); // Optionally trigger verification here
    }
  };

  return (
    <div className="codeEntry">
      <div className="title-wrapper">
        <div className="title">We texted you a code</div>
        <div className="greyText">{phoneNumber}</div>
      </div>
      <div className="greyText">
        Enter the 4 digit code sent via SMS
      </div>
      <div className="code">
        {inputRefs.map((ref, i)=>(
          <div className="gradientBg">
            <div className="blackBg">
              <input
                key={"code"+i}
                ref={ref}
                type="numeric"
                inputMode="numeric"
                className="invisibleInput"
                placeholder="_"
                maxLength={1}
                onChange={(e)=>handleChange(e, i)}
                onKeyUp={(e)=>handleKeyUp(e, i)}
                onPaste={(e)=>handleInputPaste(e, i)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
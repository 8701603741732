export const LiveBets = (props) => {
  return (
    <div id="wsbControllerLiveBets">
      <div className="gradientBg">
        <div className="blackBg">
          hi
        </div>
      </div>
    </div>
  )
}